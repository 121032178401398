import { Dispatch } from "redux";
import { COMPLAINT_TYPE } from "../containers/SubmitComplaint/SelectComplaintType";
import { GenderEnum } from "./auth.action";
import { setAxiosToken } from "../utils/AxiosToken";
import { API_URL } from "../utils/api";
import axios from "axios";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";
import { errorToText } from "../utils/functions";
import { LanguageEnum } from "../components/SelectService/SelectService";

export interface CustomerUpdateData {
  service_complaint_id: string;
  product_complaint_id: string;
  complaint_id: string;
  customer_id: string;
  names: string;
  phone_number: string;
  email: string;
  gender: GenderEnum;
  age: string;
  country_code: string;
  district_code: string;
  complaint_key: COMPLAINT_TYPE;
  complaint_tracking_code: string;
  complaint_lang: LanguageEnum;
  type: COMPLAINT_TYPE;
}

/**
 * Edit users documents
 * @param data
 * @param user_id
 * @param callback
 * @returns
 */

export const FC_SubmitCustomerInfo = (
  data: CustomerUpdateData,
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string } | null
  ) => void
) => {
  console.log("Testing data: ", data);
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/customers`, data);

      console.log({ submit_customer_info: res.data });

      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Customer info has been added successfully",
      });
      callback(false, {
        type: "SUCCESS",
        msg: "Customer info has been added successfully",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
