import { combineReducers } from "redux";
import { Auth, ServicesStore, System } from "../actions";
import { UnitStore } from "../actions/units.action";
import { authReducer } from "./auth.reducer";
import { systemReducer } from "./system.reducer";
import { unitsReducer } from "./units.reducer";
import { servicesReducer } from "./services.reducer";

// define the entire state into the entire side
export interface StoreState {
  auth: Auth;
  system: System;
  units: UnitStore;
  services: ServicesStore;
}

export const reducers = combineReducers<StoreState>({
  auth: authReducer,
  system: systemReducer,
  units: unitsReducer,
  services: servicesReducer,
});
