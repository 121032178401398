import { IconType } from "react-icons";
import {
  MdOutlineDashboard,
  MdOutlinePersonPinCircle,
  MdTravelExplore,
} from "react-icons/md";
import { HiOutlineBriefcase, HiOutlineUser } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { UserAccessList } from "./userAccess";
import {
  FaAward,
  FaChalkboardTeacher,
  FaChartBar,
  FaUsersCog,
} from "react-icons/fa";
import { IoMdAdd, IoMdWalk } from "react-icons/io";
import {
  BsCalendar2Check,
  BsJournalCheck,
  BsPersonLinesFill,
} from "react-icons/bs";

export enum MENU_TYPE {
  NONE = "NONE",
  PROFILE = "PROFILE",
  ACTIVITIES = "ACTIVITIES",
  REPORTS = "REPORTS",
  SETTINGS = "SETTINGS",
}

export interface NavigationInterface {
  title: string;
  url: string;
}

export interface SideNavigationInterface {
  title: string;
  url: string;
  icon: IconType;
  label: string;
  menu_type: MENU_TYPE;
  access: UserAccessList | "all";
}

/**
 * @description Appear allways
 * @done_by Emmy
 */
export const PUBLIC: NavigationInterface[] = [
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is not logged in
 * @done_by Emmy
 */
export const NON_AUTHENTICATED_MENUS: NavigationInterface[] = [
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Login",
    url: "/login",
  },
];

/**
 * @description Appear once the user is logged in
 * @done_by Emmy
 */
export const AUTHENTICATED_MENUS: SideNavigationInterface[] = [
  {
    icon: MdOutlineDashboard,
    title: "Homepage",
    label: "Homepage",
    url: "/dashboard",
    menu_type: MENU_TYPE.NONE,
    access: "all",
  },
  {
    icon: FaChartBar,
    title: "Dashboards",
    label: "Dashboards",
    url: "/admin-dashboard",
    menu_type: MENU_TYPE.NONE,
    access: UserAccessList.DASHBOARDS_MENU,
  },
  {
    icon: HiOutlineUser,
    title: "My Profile",
    label: "My Profile",
    url: "/profile",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
  },
  {
    icon: FaAward,
    title: "Academic Background",
    label: "Academic Background",
    url: "/job-background",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
  },
  {
    icon: RiLockPasswordLine,
    title: "Change Password",
    label: "Change Password",
    url: "/change-password",
    menu_type: MENU_TYPE.PROFILE,
    access: "all",
  },
  {
    icon: HiOutlineBriefcase,
    title: "Positions Management",
    label: "Positions Management",
    url: "/positions-management",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.POSITIONS,
  },
  {
    icon: FaUsersCog,
    title: "Staff Management",
    label: "Staff Management",
    url: "/employees-management",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_LIST,
  },
  {
    icon: FaUsersCog,
    title: "Unit staff",
    label: "Unit staff",
    url: "/unit-staffs",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_BY_UNIT,
  },
  {
    icon: BsCalendar2Check,
    title: "Training Plans",
    label: "Training Plans",
    url: "/training-plans",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEES_TRAINING_PLANS,
  },
  {
    icon: BsJournalCheck,
    title: "Training Evaluation",
    label: "Training Evaluation",
    url: "/training-evaluation",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_TRAININGS,
  },
  {
    icon: MdOutlinePersonPinCircle,
    title: "Staff Leaves",
    label: "Staff Leaves",
    url: "/employee-leaves-by-line-manager",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEE_LEAVE,
  },
  {
    icon: MdOutlinePersonPinCircle,
    title: "All Leaves",
    label: "All Leaves",
    url: "/all-employee-leaves",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_EMPLOYEE_LEAVE,
  },
  {
    icon: BsPersonLinesFill,
    title: "Staff Travels",
    label: "Staff Travels",
    url: "/employee-travels-by-line-manager",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.EMPLOYEE_TRAVEL,
  },
  {
    icon: BsPersonLinesFill,
    title: "All Travels",
    label: "All Travels",
    url: "/all-employee-travels",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_EMPLOYEE_TRAVEL,
  },
  {
    icon: FaChalkboardTeacher,
    title: "My Training Reports",
    label: "My Training Reports",
    url: "/employee-trainings",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.EMPLOYEE_TRAINING,
  },
  {
    icon: IoMdAdd,
    title: "Create Trainings",
    label: "Create Trainings",
    url: "/create-training",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.CREATE_TRAINING_MENU,
  },
  {
    icon: FaChalkboardTeacher,
    title: "Unit Trainings",
    label: "Unit Trainings",
    url: "/trainings-by-unit",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.TRAININGS_BY_UNIT,
  },
  {
    icon: FaChalkboardTeacher,
    title: "Trainings by plans",
    label: "Trainings by plans",
    url: "/trainings-by-plan",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.TRAININGS_BY_PLAN,
  },
  {
    icon: FaChalkboardTeacher,
    title: "All Trainings",
    label: "All Trainings",
    url: "/trainings-by-year",
    menu_type: MENU_TYPE.ACTIVITIES,
    access: UserAccessList.ALL_TRAININGS,
  },
  {
    icon: IoMdWalk,
    title: "My Leaves",
    label: "My Leaves",
    url: "/employee-leave",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.LEAVES,
  },
  {
    icon: MdTravelExplore,
    title: "My Travel Clearance",
    label: "My Travel Clearance",
    url: "/employee-travel",
    menu_type: MENU_TYPE.PROFILE,
    access: UserAccessList.TRAVELS,
  },
];

export const menus_categories = (): { key: MENU_TYPE; title: string }[] => {
  const response: { key: MENU_TYPE; title: string }[] = [];
  for (const menu in MENU_TYPE) {
    response.push({
      key: menu as MENU_TYPE,
      title:
        menu === MENU_TYPE.PROFILE
          ? "Profile"
          : menu === MENU_TYPE.ACTIVITIES
          ? "Activities"
          : menu === MENU_TYPE.REPORTS
          ? "Reports"
          : menu === MENU_TYPE.SETTINGS
          ? "Settings"
          : "",
    });
  }
  return response.filter((element) =>
    response.find((itm) => itm.key === element.key)
  );
};
