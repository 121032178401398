import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  ComplaintStatus,
  FC_GetLocationsDetails,
  LocationAPI,
  ProductComplaintDetailsInterface,
  System,
} from "../../actions";
import { connect } from "react-redux";
import { MdClose } from "react-icons/md";
import { BiLoaderCircle, BiMessageRoundedCheck } from "react-icons/bi";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { DATE, GenerateLangText } from "../../utils/functions";
import { LanguageEnum } from "../SelectService/SelectService";
import { SubmitProductFeedback } from "./SubmitProductFeedback";
import { API_URL } from "../../utils/api";
import PdfViewer from "../PdfViewer/PdfViewer";

interface ComplaintDetailsProps {
  system: System;
  complaintDetails: ProductComplaintDetailsInterface;
  selectedLanguage: LanguageEnum;
  onBack: () => void;
  onSubmitFeedback: (
    data: {
      customer_feedback: string;
      product_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => void;
}
interface ComplaintDetailsState {
  loading: boolean;
  addComplaintResponseFeedback: {
    status: ComplaintStatus;
  } | null;
  locations_loading: boolean;
  locations: LocationAPI | null;
  error: string;
  viewProductImage: boolean;
}

class _ComplaintDetails extends Component<
  ComplaintDetailsProps,
  ComplaintDetailsState
> {
  constructor(props: ComplaintDetailsProps) {
    super(props);
    this.state = {
      loading: false,
      addComplaintResponseFeedback: null,
      locations_loading: false,
      locations: null,
      error: "",
      viewProductImage: false,
    };
  }
  GetLocations = () => {
    this.setState({ locations_loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        if (res?.type === "Success") {
          this.setState({
            locations_loading: loading,
            locations: res.data,
            error: "",
          });
        }
        if (res?.type === "Error") {
          this.setState({
            locations_loading: false,
            error: res.msg,
          });
        }
      }
    );
  };
  GetCountryName = (countryCode: string) => {
    const country = this.state.locations?.countries.find(
      (itm) => itm.country_code === countryCode
    );
    if (country !== undefined) {
      return country.country_name;
    }
    return "";
  };
  GetDistrictName = (districtCode: string) => {
    const country = this.state.locations?.districts.find(
      (itm) => itm.district_code === districtCode
    );
    if (country !== undefined) {
      return country.district_name;
    }
    return "";
  };
  componentDidMount(): void {
    this.state.locations === null && this.GetLocations();
  }
  render() {
    return (
      <div>
        <div className="p-4 pb-0 pt-0">
          <div className="bg-primary-50 border border-primary-300 p-4 rounded-md">
            <div className="border-b pb-2 mb-2">
              <div className="text-sm">
                {GenerateLangText(
                  {
                    eng: "Tracking Code",
                    fr: "Code de suivi",
                    kiny: "Kode yo gukurikirana ikibazo",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="font-bold text-primary-700">
                {this.props.complaintDetails.complaint_tracking_code}
              </div>
            </div>
            <div className="border-b pb-2 mb-2">
              <div className="text-sm">
                {GenerateLangText(
                  {
                    eng: "Service Category",
                    fr: "Catégorie de services",
                    kiny: "Icyiciro cya serivisi",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="font-bold">
                {this.props.selectedLanguage === LanguageEnum.ENG
                  ? this.props.complaintDetails.eng_service_category
                  : this.props.selectedLanguage === LanguageEnum.FR
                  ? this.props.complaintDetails.fr_service_category
                  : this.props.complaintDetails.kiny_service_category}
              </div>
            </div>
            {/*  */}
            <div className="">
              <div className="text-sm">
                {GenerateLangText(
                  {
                    eng: "Service",
                    fr: "Service",
                    kiny: "Serivisi",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="font-bold">
                {this.props.selectedLanguage === LanguageEnum.ENG
                  ? this.props.complaintDetails.eng_service
                  : this.props.selectedLanguage === LanguageEnum.FR
                  ? this.props.complaintDetails.fr_service
                  : this.props.complaintDetails.kiny_service}
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 py-2">
          <div className="bg-yellow-50 border border-yellow-300 p-4 rounded-md">
            <div className="border-b pb-2 mb-2">
              <div className="text-sm text-gray-500">
                {GenerateLangText(
                  {
                    eng: "Query type",
                    fr: "Type de requête",
                    kiny: "Ubwoko bw'ikibazo/icyifuzo",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="text-yellow-700">
                {GenerateLangText(
                  {
                    eng: this.props.complaintDetails.complaint_reason_en,
                    fr: this.props.complaintDetails.complaint_reason_fr,
                    kiny: this.props.complaintDetails.complaint_reason_kiny,
                  },
                  this.props.system.language
                )}
              </div>
            </div>
            {this.props.complaintDetails.product_image_name !== null &&
              (this.state.viewProductImage === false ? (
                <div className="mb-2">
                  <div className="text-sm text-gray-500">
                    {GenerateLangText(
                      {
                        eng: "Product Image",
                        fr: "La photo du produit",
                        kiny: "Ifoto/inyandiko",
                      },
                      this.props.selectedLanguage
                    )}
                  </div>
                  <div className=" pt-2">
                    <div
                      onClick={() =>
                        this.setState({
                          viewProductImage: !this.state.viewProductImage,
                        })
                      }
                      className="px-4 py-2 rounded-md bg-white font-bold text-sm w-max cursor-pointer border border-yellow-400 hover:border-white hover:bg-yellow-600 hover:text-white"
                    >
                      {GenerateLangText(
                        {
                          eng: "Click to view product photo",
                          fr: "Cliquez pour voir la photo du produit",
                          kiny: "Kanda urebe Ifoto/inyandiko",
                        },
                        this.props.selectedLanguage
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="rounded-md overflow-hidden bg-gray-500 p-3 mb-3 text-white animate__animated animate__fadeIn">
                  <div className="flex flex-row items-center justify-between gap-2 mb-3">
                    <div className="font-bold text-lg pl-4">
                      {GenerateLangText(
                        {
                          eng: "Product Image preview",
                          fr: "Aperçu de l'image du produit",
                          kiny: "Ifoto/inyandiko y'igicuruzwa",
                        },
                        this.props.selectedLanguage
                      )}
                    </div>
                    <div>
                      <div
                        onClick={() =>
                          this.setState({ viewProductImage: false })
                        }
                        className="flex items-center justify-center rounded-full bg-gray-400 text-white hover:bg-gray-700 cursor-pointer h-8 w-8"
                      >
                        <MdClose className="text-2xl" />
                      </div>
                    </div>
                  </div>
                  {this.props.complaintDetails.product_image_name.includes(
                    "pdf"
                  ) === false ? (
                    <img
                      className="w-full h-auto rounded-md"
                      src={`${API_URL}/image/${this.props.complaintDetails.product_image_name}`}
                      alt={this.props.complaintDetails.product_name}
                    />
                  ) : (
                    <PdfViewer
                      file_url={`${API_URL}/image/${this.props.complaintDetails.product_image_name}`}
                      class_name={"w-full min-h-screen rounded-md"}
                      frame_title={"Product preview"}
                      setLoadingFile={(state: boolean) =>
                        this.setState({ loading: state })
                      }
                    />
                  )}
                </div>
              ))}
            <div className="border-b pb-2 mb-2">
              <div className="text-sm text-gray-500">
                {GenerateLangText(
                  {
                    eng: "Query Description",
                    fr: "Description de requête",
                    kiny: "Ibisobanuro ku kibazo/icyifuzo",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="text-sm">
                {this.props.complaintDetails.description}
              </div>
            </div>
            <div
              className={`${
                this.props.complaintDetails.country_code !== null
                  ? "border-b pb-2 mb-2"
                  : ""
              }`}
            >
              <div className="text-sm text-gray-500">
                {GenerateLangText(
                  {
                    eng: "Query Date",
                    fr: "Date de requête",
                    kiny: "Igihe ikibazo cyabarijweho",
                  },
                  this.props.system.language
                )}
              </div>
              <div className="text-sm">
                {DATE(this.props.complaintDetails.created_at)}
              </div>
            </div>
            <div
              className={`flex flex-row items-center justify-between w-full gap-3`}
            >
              {this.props.complaintDetails.country_code !== null && (
                <div className={`w-full`}>
                  <div className="text-sm text-gray-500">
                    {GenerateLangText(
                      {
                        eng: "Query Country location",
                        fr: "Emplacement du pays de requête",
                        kiny: "Igihugu",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div className="text-sm">
                    {this.GetCountryName(
                      this.props.complaintDetails.country_code
                    )}
                  </div>
                </div>
              )}
              {this.props.complaintDetails.district_code !== null && (
                <div className="w-full">
                  <div className="text-sm text-gray-500">
                    {GenerateLangText(
                      {
                        eng: "Query District location",
                        fr: "Localisation du district de requête",
                        kiny: "Akarere",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div className="text-sm">
                    {this.GetDistrictName(
                      this.props.complaintDetails.district_code
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.complaintDetails.phone_number !== null && (
          <div className="p-4 pt-0 pb-0">
            <div className="bg-gray-100 border border-gray-300 p-4 rounded-md">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <div className="font-bold text-xl">
                    {GenerateLangText(
                      {
                        eng: "Customer Details",
                        fr: "Détails du client",
                        kiny: "Amakuru ku mukiriya",
                      },
                      this.props.system.language
                    )}
                  </div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Full names",
                        fr: "Noms complets",
                        kiny: "Amazina yose",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>{this.props.complaintDetails.names}</div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Gender",
                        fr: "Genre",
                        kiny: "Igitsina",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>{this.props.complaintDetails.gender}</div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Age",
                        fr: "Âge",
                        kiny: "Imyaka",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>{this.props.complaintDetails.age}</div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Phone number",
                        fr: "Numéro de téléphone",
                        kiny: "Numero ya telefoni",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>{this.props.complaintDetails.phone_number}</div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Email",
                        fr: "E-mail",
                        kiny: "Imeri",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>{this.props.complaintDetails.email}</div>
                </div>
                <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                  <div className="text-gray-600">
                    {GenerateLangText(
                      {
                        eng: "Country",
                        fr: "Pays",
                        kiny: "Igihugu",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div>
                    {this.props.complaintDetails.country_code !== null &&
                      this.GetCountryName(
                        this.props.complaintDetails.country_code
                      )}
                  </div>
                </div>
                {this.props.complaintDetails.district_code !== null && (
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col text-sm">
                    <div className="text-gray-600">
                      {GenerateLangText(
                        {
                          eng: "District",
                          fr: "District",
                          kiny: "Akarere",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div>
                      {this.GetDistrictName(
                        this.props.complaintDetails.district_code
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {this.state.addComplaintResponseFeedback === null ? (
          <div className="pt-4">
            {this.props.complaintDetails.complaint_status ===
            ComplaintStatus.PENDING ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-yellow-500 flex flex-row items-center justify-between gap-3 w-full rounded-lg">
                <div>
                  <div className="text-sm">
                    {GenerateLangText(
                      {
                        eng: "Current Status",
                        fr: "Statut actuel",
                        kiny: "Aho ikibazo kigeze",
                      },
                      this.props.system.language
                    )}
                  </div>
                  <div className="font-bold text-xl text-yellow-400 animate__animated animate__fadeIn animate__slower animate__infinite">
                    {GenerateLangText(
                      {
                        eng: "Waiting for response",
                        fr: "En attente d'une réponse",
                        kiny: "Ntabwo ikibazo kirasubizwa",
                      },
                      this.props.system.language
                    )}
                  </div>
                </div>
              </div>
            ) : this.props.complaintDetails.complaint_status ===
              ComplaintStatus.PROGRESS ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-primary-700 flex flex-row items-center justify-between gap-3 w-full rounded-lg">
                <div>
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-primary-300 flex flex-row items-center gap-3">
                    <div className="animate__animated animate__fadeIn animate__slower animate__infinite">
                      <BiLoaderCircle className="text-5xl animate-spin text-yellow-400" />
                    </div>
                    <div>
                      <div className="text-yellow-200">
                        {GenerateLangText(
                          {
                            eng: "In Progress",
                            fr: "En cours",
                            kiny: "Biri gukorwaho",
                          },
                          this.props.selectedLanguage
                        )}
                      </div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.progress_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        {GenerateLangText(
                          {
                            eng: "Date:",
                            fr: "Date:",
                            kiny: "Itariki:",
                          },
                          this.props.selectedLanguage
                        )}{" "}
                        {this.props.complaintDetails.progress_date !== null &&
                          DATE(this.props.complaintDetails.progress_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <div
                    onClick={() =>
                      this.setState({
                        addComplaintResponseFeedback: {
                          status: ComplaintStatus.COMPLETED,
                        },
                      })
                    }
                    className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                  >
                    <div>
                      <BiMessageRoundedCheck className="text-2xl" />
                    </div>
                    <span>Submit Response</span>
                  </div>
                </div> */}
              </div>
            ) : this.props.complaintDetails.complaint_status ===
              ComplaintStatus.COMPLETED ? (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-green-600 flex flex-row items-center justify-between gap-3 w-full rounded-lg">
                <div className="w-full">
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-green-300 flex flex-row items-center gap-3">
                    <div className="">
                      <BsFillCheckCircleFill className="text-5xl text-green-300" />
                    </div>
                    <div>
                      <div className="text-white">
                        {GenerateLangText(
                          {
                            eng: "Resolved",
                            fr: "Résolue",
                            kiny: "Byakemutse",
                          },
                          this.props.selectedLanguage
                        )}
                      </div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.completed_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        {GenerateLangText(
                          {
                            eng: "Date:",
                            fr: "Date:",
                            kiny: "Itariki:",
                          },
                          this.props.selectedLanguage
                        )}{" "}
                        {this.props.complaintDetails.completed_date !== null &&
                          DATE(this.props.complaintDetails.completed_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.complaintDetails.customer_feedback !== null ? (
                  <div className="w-full">
                    <div>
                      <div className="font-bold">
                        {GenerateLangText(
                          {
                            eng: "Customer Feedback",
                            fr: "Commentaires des clients",
                            kiny: "Ibitekerezo",
                          },
                          this.props.selectedLanguage
                        )}
                      </div>
                      <div className="text-sm text-gray-200 font-light">
                        {this.props.complaintDetails.customer_feedback}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        {GenerateLangText(
                          {
                            eng: "Date:",
                            fr: "Date:",
                            kiny: "Itariki:",
                          },
                          this.props.selectedLanguage
                        )}{" "}
                        {this.props.complaintDetails.customer_feedback_date !==
                          null &&
                          DATE(
                            this.props.complaintDetails.customer_feedback_date
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      onClick={() =>
                        this.setState({
                          addComplaintResponseFeedback: {
                            status: ComplaintStatus.PROGRESS,
                          },
                        })
                      }
                      className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                    >
                      <div>
                        <BiMessageRoundedCheck className="text-2xl" />
                      </div>
                      <span>
                        {GenerateLangText(
                          {
                            eng: "Submit Feedback",
                            fr: "Soumettre des commentaires",
                            kiny: "Ohereza igitekerezo",
                          },
                          this.props.selectedLanguage
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="p-4 bg-gray-600 text-white border-t-4 border-red-500 flex flex-row items-center justify-between gap-3 w-full rounded-lg">
                <div className="w-full">
                  {/* <div className="text-sm">Current Status</div> */}
                  <div className="font-bold text-xl text-red-300 flex flex-row items-center gap-3">
                    <div className="">
                      <MdClose className="text-5xl text-red-300" />
                    </div>
                    <div>
                      <div className="text-white">Rejected</div>
                      <div className="text-sm text-gray-300 font-normal">
                        {this.props.complaintDetails.rejected_reason}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        {GenerateLangText(
                          {
                            eng: "Date:",
                            fr: "Date:",
                            kiny: "Itariki:",
                          },
                          this.props.selectedLanguage
                        )}{" "}
                        {this.props.complaintDetails.rejected_date !== null &&
                          DATE(this.props.complaintDetails.rejected_date)}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.complaintDetails.customer_feedback !== null ? (
                  <div className="w-full">
                    <div>
                      <div className="font-bold">
                        {GenerateLangText(
                          {
                            eng: "Customer Feedback",
                            fr: "Commentaires des clients",
                            kiny: "Ibitekerezo",
                          },
                          this.props.selectedLanguage
                        )}
                      </div>
                      <div className="text-sm text-gray-200 font-light">
                        {this.props.complaintDetails.customer_feedback}
                      </div>
                      <div className="text-xs text-white font-normal bg-gray-500 w-max px-2 rounded-full">
                        {GenerateLangText(
                          {
                            eng: "Date:",
                            fr: "Date:",
                            kiny: "Itariki:",
                          },
                          this.props.selectedLanguage
                        )}{" "}
                        {this.props.complaintDetails.customer_feedback_date !==
                          null &&
                          DATE(
                            this.props.complaintDetails.customer_feedback_date
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      onClick={() =>
                        this.setState({
                          addComplaintResponseFeedback: {
                            status: ComplaintStatus.PROGRESS,
                          },
                        })
                      }
                      className="flex flex-row items-center justify-center gap-2 w-max px-3 py-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                    >
                      <div>
                        <BiMessageRoundedCheck className="text-2xl" />
                      </div>
                      <span>
                        {GenerateLangText(
                          {
                            eng: "Submit Feedback",
                            fr: "Soumettre des commentaires",
                            kiny: "Tanga ibitekerezo ku gisubizo",
                          },
                          this.props.system.language
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="p-4">
            <SubmitProductFeedback
              onBack={() =>
                this.setState({ addComplaintResponseFeedback: null })
              }
              selectedComplaint={this.props.complaintDetails}
              onSubmitFeedback={this.props.onSubmitFeedback}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return { auth, system };
};

export const ProductComplaintDetailsComponent = connect(
  mapStateToProps,
  {}
)(_ComplaintDetails);
