import { EmploymentItem } from "../actions";

export enum UserAccessList {
  COMPETENCY_SETTINGS = "COMPETENCY_SETTINGS",
  COMPETENCY_FUNCTION_SETTINGS = "COMPETENCY_FUNCTION_SETTINGS",
  COMPETENCY_CATEGORY_SETTINGS = "COMPETENCY_CATEGORY_SETTINGS",
  COMPETENCY_DOMAIN_SETTINGS = "COMPETENCY_DOMAIN_SETTINGS",
  COMPETENCY_BEHAVIOR_SETTINGS = "COMPETENCY_BEHAVIOR_SETTINGS",
  JOB_FAMILIES = "JOB_FAMILIES",
  QUALIFICATION_SETTINGS = "QUALIFICATION_SETTINGS",
  PROFICIENCY_LEVELS_SETTINGS = "PROFICIENCY_LEVELS_SETTINGS",
  TRAINING_PROVIDERS_SETTINGS = "TRAINING_PROVIDERS_SETTINGS",
  POSITIONS = "POSITIONS",
  POSITION_COMPETENCY = "POSITION_COMPETENCY",
  POSITION_ACCESS = "POSITION_ACCESS",
  STAFFING_LEVEL = "STAFFING_LEVEL",
  EMPLOYEES_LIST = "EMPLOYEES_LIST",
  EMPLOYEE_PERSONAL_INFO = "EMPLOYEE_PERSONAL_INFO",
  EMPLOYEE_POSITION_DETAILS = "EMPLOYEE_POSITION_DETAILS",
  EMPLOYEE_BEHAVIORS = "EMPLOYEE_BEHAVIORS",
  EMPLOYEE_POSITION_COMPETENCIES = "EMPLOYEE_POSITION_COMPETENCIES",
  EMPLOYEE_TRAINING = "EMPLOYEE_TRAINING",
  EMPLOYEE_LEAVE = "EMPLOYEE_LEAVE",
  EMPLOYEE_TRAVEL = "EMPLOYEE_TRAVEL",
  COMPETENCY_MANAGEMENT = "COMPETENCY_MANAGEMENT",
  COMPETENCY_EVALUATION = "COMPETENCY_EVALUATION",
  EMPLOYEES_TRAINING_PLANS = "EMPLOYEES_TRAINING_PLANS",
  TRAINING_PARTICIPANTS = "TRAINING_PARTICIPANTS",
  APPROVE_EMPLOYEE_ATTENDED_TRAINING = "APPROVE_EMPLOYEE_ATTENDED_TRAINING",
  // TRAINING_PLANS = "TRAINING_PLANS",
  REPORT_ATTENDED_TRAINING = "REPORT_ATTENDED_TRAINING",
  LEAVE_REQUEST_APPROVAL = "LEAVE_REQUEST_APPROVAL",
  LEAVE_RETURN_APPROVAL = "LEAVE_RETURN_APPROVAL",
  LEAVES = "LEAVES",
  REQUEST_FOR_LEAVE = "REQUEST_FOR_LEAVE",
  REPORT_LEAVE_RETURN = "REPORT_LEAVE_RETURN",
  TRAVEL_REQUEST_APPROVAL = "TRAVEL_REQUEST_APPROVAL",
  TRAVEL_RETURN_APPROVAL = "TRAVEL_RETURN_APPROVAL",
  TRAVELS = "TRAVELS",
  REQUEST_FOR_TRAVEL = "REQUEST_FOR_TRAVEL",
  REPORT_TRAVEL_RETURN = "REPORT_TRAVEL_RETURN",
  TRAININGS_BY_PLAN = "TRAININGS_BY_PLAN",
  ALL_TRAININGS = "ALL_TRAININGS",
  TRAININGS_BY_UNIT = "TRAININGS_BY_UNIT",
  TRAININGS_BY_USER = "TRAININGS_BY_USER",
  TRAINING_EVALUATION = "TRAINING_EVALUATION",
  EMPLOYEES_DASHBOARD = "EMPLOYEES_DASHBOARD",
  TRAININGS_DASHBOARD = "TRAININGS_DASHBOARD",
  LEAVES_DASHBOARD = "LEAVES_DASHBOARD",
  TRAVEL_DASHBOARD = "TRAVEL_DASHBOARD",
  DASHBOARDS_MENU = "DASHBOARDS_MENU",
  EMPLOYEES_BY_UNIT = "EMPLOYEES_BY_UNIT",
  ALL_EMPLOYEE_LEAVE = "ALL_EMPLOYEE_LEAVE",
  ALL_EMPLOYEE_TRAVEL = "ALL_EMPLOYEE_TRAVEL",
  CREATE_TRAINING_MENU = "CREATE_TRAINING_MENU",
}

export interface UserAccessInterface {
  key: UserAccessList;
  permission: {
    view: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
    export: boolean;
  };
}

export const isAccessAuthorized = (
  selectedEmployment: EmploymentItem | null,
  access: UserAccessList
): {
  view: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  export: boolean;
} => {
  if (selectedEmployment !== null) {
    const response = selectedEmployment.access.find(
      (itm) => itm.key === access
    );
    if (response !== undefined) {
      return response.permission;
    }
  }
  return {
    create: false,
    delete: false,
    export: false,
    update: false,
    view: false,
  };
};
