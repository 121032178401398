// const testingAPI = "https://deafactionrda.org/hr/api";
// This is for production server
export const server = "https://customersupport.rwandafda.gov.rw";
export const API_URL = `${server}/supportapi`;
export const DISTRICTS_LOCATION = `${server}/locations.json`;
// export const DISTRICTS_LOCATION = `https://localhost:3001/support/locations.json`; // TEMPORARY LINK

//** Socket server */
export const SOCKET_SERVER = "https://customersupport.rwandafda.gov.rw";

// Files location
export const IMAGES_PATH = {};
export enum DocFolder {
  docs = "docs",
}
