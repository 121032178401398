import { Action, ActionTypes, ServicesStore } from "../actions";
// default state
const defaultState: ServicesStore = {
  services: null,
  serviceComplaintTypes: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const servicesReducer = (
  state: ServicesStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_SERVICES:
      return {
        ...state,
        services: action.payload,
      };
    case ActionTypes.GET_SERVICE_COMPLAINT_TYPES:
      return {
        ...state,
        serviceComplaintTypes: action.payload,
      };
    default:
      return state;
  }
};
