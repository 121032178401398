import React, { useCallback } from "react";
import { Accept, useDropzone } from "react-dropzone";
import Alert, { AlertType } from "../Alert/Alert";
import { LanguageEnum } from "../SelectService/SelectService";
import { GenerateLangText } from "../../utils/functions";

export interface RejectedFileInterface {
  file: File;
  errors: {
    code: string;
    message: string;
  }[];
}

interface DragDropFileProps {
  className: string;
  allowedFileTypes: Accept;
  acceptedFiles: { file: File; preview: string }[];
  rejectedFiles: RejectedFileInterface[];
  setAcceptedFiles: (acceptedFiles: { file: File; preview: string }[]) => void;
  setRejectedFiles: (files: RejectedFileInterface[]) => void;
  maxFiles?: number;
  maxSize?: number;
  disabled: boolean;
  multiple: boolean;
  cleanErrors?: () => void;
  hideDragDrop?: boolean;
  language: LanguageEnum;
}

export const DragDropFile = (props: DragDropFileProps) => {
  //   const [files, setFiles] = useState<{ file: File; preview: string }[]>([]);
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: RejectedFileInterface[]) => {
      if (
        acceptedFiles !== null &&
        acceptedFiles !== undefined &&
        acceptedFiles.length > 0
      ) {
        props.setAcceptedFiles([
          //   ...props.acceptedFiles,
          // ...acceptedFiles.map((file: Blob | MediaSource) =>
          //   Object.assign(file, { preview: URL.createObjectURL(file) })
          // ),
          ...acceptedFiles.map((file: File) => ({
            file: file,
            preview: URL.createObjectURL(file),
          })),
        ]);
      }
      if (rejectedFiles?.length > 0) {
        props.setRejectedFiles([
          // ...props.rejectedFiles,
          ...rejectedFiles,
        ]);
      }
    },
    [props]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: props.allowedFileTypes,
    maxFiles: props.maxFiles,
    maxSize: props.maxSize,
    disabled: props.disabled,
    multiple: props.multiple,
  });

  return (
    <form>
      {props.hideDragDrop !== true && (
        <div
          {...getRootProps({
            className: `${props.className}`,
          })}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>
              {props.language !== undefined ? (
                GenerateLangText(
                  {
                    eng: `Drop the file${
                      props.multiple === true ? "s" : ""
                    } here ...`,
                    fr: "Faites glisser 'et' déposez le fichier ici, ou cliquez pour sélectionner le fichier",
                    kiny: "Kanda hano kugirango uhitemo dosiye",
                  },
                  props.language
                )
              ) : (
                <span>
                  Drop the file{props.multiple === true ? "s" : ""} here ...
                </span>
              )}
            </p>
          ) : (
            <div>
              <p>
                {props.multiple === true
                  ? GenerateLangText(
                      {
                        eng: "Drag 'n' drop some files here, or click to select files",
                        fr: "Glissez et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
                        kiny: "Kanda hano kugirango uhitemo dosiye",
                      },
                      props.language
                    )
                  : GenerateLangText(
                      {
                        eng: "Drag 'n' drop some files here, or click to select files",
                        fr: "Glissez et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
                        kiny: "Kanda hano kugirango uhitemo dosiye",
                      },
                      props.language
                    )}
              </p>
              <p className="text-xs font-bold text-yellow-600 pt-2">
                (10 MBs max)
              </p>
            </div>
          )}
        </div>
      )}
      {/* Errors */}
      {props.rejectedFiles.length > 0 && (
        <div className="mt-2">
          {props.rejectedFiles.map((error, err) => (
            <div key={err + 1}>
              {error.errors.length > 0 && (
                <Alert
                  alertType={AlertType.DANGER}
                  close={() => (props.cleanErrors ? props.cleanErrors() : {})}
                  title={error.file.name}
                  description={error.errors[0].message}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </form>
  );
};
