import React from "react";
import Alert, { AlertType } from "../Alert/Alert";

const Select = (props: {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled: boolean;
  value: string;
  error: string;
  onCloseError?: () => void;
  className?: string;
  data: { value: string; caption: string }[];
  requiredStar?: boolean;
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-row items-center gap-1">
        <div className="text-sm pb-2">{props.title}</div>
        {props.requiredStar !== undefined && props.requiredStar === true && (
          <div className="flex flex-row items-center">
            <div className="text-sm text-red-500 flex flex-row items-start">
              (<span className="text-xl">*</span>)
            </div>
          </div>
        )}
      </div>
      <select
        className={`px-3 py-2 text-sm w-full border ${
          props.error !== "" ? "border-red-600" : ""
        } rounded-md ${
          props.className === undefined ? "bg-gray-100" : props.className
        }`}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
      >
        <option value=""></option>
        {props.data.map((item, i) => (
          <option key={i + 1} value={item.value}>
            {item.caption}
          </option>
        ))}
      </select>
      {props.error !== "" && (
        <div>
          <Alert
            alertType={AlertType.DANGER}
            title={props.error}
            close={() => {
              props.onCloseError !== undefined && props.onCloseError();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Select;
