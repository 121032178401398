import React, { Component } from "react";
import { FAQuestionInterface } from "../../actions";
import { LanguageEnum } from "../SelectService/SelectService";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

interface FAQItemProps {
  data: FAQuestionInterface;
  selectedLanguage: LanguageEnum;
  openedFaq: FAQuestionInterface | null;
  setOpenedFAQ: (openedFaq: FAQuestionInterface | null) => void;
}
interface FAQItemState {}

export class FAQItem extends Component<FAQItemProps, FAQItemState> {
  constructor(props: FAQItemProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div
        className={`w-full ${
          this.props.openedFaq?.faq_id === this.props.data.faq_id
            ? "bg-primary-50 border-primary-100"
            : "bg-gray-100 hover:bg-white border-white hover:border-gray-300"
        } cursor-pointer rounded-md border`}
      >
        <div
          onClick={() => this.props.setOpenedFAQ(this.props.data)}
          className={`flex flex-row items-center justify-between gap-3 w-full px-2 py-3 pl-3 pb-2 ${
            this.props.openedFaq?.faq_id === this.props.data.faq_id
              ? "hover:bg-primary-700 hover:text-white group rounded-t-md font-bold"
              : ""
          }`}
        >
          <div>
            {this.props.selectedLanguage === LanguageEnum.ENG
              ? this.props.data.question_en
              : this.props.selectedLanguage === LanguageEnum.FR
              ? this.props.data.question_fr
              : this.props.data.question_kiny}
          </div>
          <div>
            {this.props.openedFaq?.faq_id !== this.props.data.faq_id ? (
              <MdKeyboardArrowRight className="text-xl text-gray-400 group-hover:text-white" />
            ) : (
              <MdKeyboardArrowDown className="text-xl text-gray-400 group-hover:text-white animate__animated animate__fadeIn" />
            )}
          </div>
        </div>
        {this.props.openedFaq?.faq_id === this.props.data.faq_id && (
          <div className="animate__animated animate__fadeInDown px-2 py-3 pl-3 pt-2 border-t border-primary-300 text-primary-900">
            <div>
              {this.props.selectedLanguage === LanguageEnum.ENG
                ? this.props.data.answer_en
                : this.props.selectedLanguage === LanguageEnum.FR
                ? this.props.data.answer_fr
                : this.props.data.answer_kiny}
            </div>
          </div>
        )}
      </div>
    );
  }
}
