import React from "react";
import Alert, { AlertType } from "../Alert/Alert";

const Input = (props: {
  title: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  value: string;
  error: string;
  onCloseError?: () => void;
  type: React.HTMLInputTypeAttribute;
  className?: string;
  requiredStar?: boolean;
}) => {
  return (
    <div>
      <div className="flex flex-row items-center gap-1">
        <div className="text-sm pb-2">{props.title}</div>
        {props.requiredStar !== undefined && props.requiredStar === true && (
          <div className="flex flex-row items-center">
            <div className="text-sm text-red-500 flex flex-row items-start">
              (<span className="text-xl">*</span>)
            </div>
          </div>
        )}
      </div>
      <input
        type={props.type}
        className={`px-3 py-2 text-sm w-full border ${
          props.error !== "" ? "border-red-600" : ""
        } rounded-md ${
          props.className === undefined ? "bg-gray-100" : props.className
        }`}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange}
      />
      {props.error !== "" && (
        <div>
          <Alert
            alertType={AlertType.DANGER}
            title={props.error}
            close={() => {
              props.onCloseError !== undefined && props.onCloseError();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Input;
