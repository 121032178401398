import io, { Socket } from "socket.io-client";
import { SOCKET_SERVER } from "../utils/api";
import { Dispatch } from "@reduxjs/toolkit";
import { ActionTypes } from "./types";
import { ComplaintStatus } from "./services.action";

//** Socket initialization */
let socket: Socket;
socket = io(SOCKET_SERVER, {
  reconnectionAttempts: 3,
  reconnectionDelayMax: 10000,
});
//**-------------------------

export interface SocketComplaintData {
  reason_id: string;
  unit_id: string;
  complaint_status: ComplaintStatus;
  complaint_reason_en: string;
}

export interface AddSocketComplaintDataAction {
  type: ActionTypes.ADD_SOCKET_COMPLAINT_DATA;
  payload: SocketComplaintData;
}

export interface GetSocketComplaintDataAction {
  type: ActionTypes.GET_SOCKET_COMPLAINT_DATA;
  payload: SocketComplaintData;
}

export const FC_AddSocketComplaint = (data: SocketComplaintData) => {
  return async (dispatch: Dispatch) => {
    socket.emit("customerComplaint", data);
    dispatch<AddSocketComplaintDataAction>({
      type: ActionTypes.ADD_SOCKET_COMPLAINT_DATA,
      payload: data,
    });
  };
};

export const FC_GetSocketComplaint = () => {
  return async (dispatch: Dispatch) => {
    socket.on("customerComplaintStatus", (data: SocketComplaintData) => {
      console.log("Socket data received: ", data);
      dispatch<GetSocketComplaintDataAction>({
        type: ActionTypes.GET_SOCKET_COMPLAINT_DATA,
        payload: data,
      });
    });
  };
};
