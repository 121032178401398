import React from "react";
import { LanguageEnum } from "../SelectService/SelectService";
import { GenerateLangText } from "../../utils/functions";

const Footer = (props: { language: LanguageEnum }) => {
  return (
    <div className="bg-primary-800 pt-5 text-white">
      <div className="text-center font-light px-6 pb-4">
        {GenerateLangText(
          {
            eng: "RWANDA FDA Customer Support Platform",
            fr: "RWANDA FDA Plate-forme d'assistance client",
            kiny: "RWANDA FDA Ihuriro ryunganira abakiriya",
          },
          props.language
        )}
      </div>
      <div className="bg-primary-900 flex flex-row items-center justify-between gap-3 w-full px-6 pb-6 pt-2">
        <span>Rwanda FDA @ copyright {new Date().getFullYear()}</span>
        <a
          href="https://universalbridge.rw"
          className="hover:underline text-gray-300 font-bold text-sm"
          target="_blank"
          rel="noreferrer"
        >
          Developer: UBDEV
        </a>
      </div>
    </div>
  );
};

export default Footer;
