import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { BooleanEnum } from "./auth.action";
import { ServiceComplaintDetails } from "../containers/TrackComplaint/TrackComplaint";
import { COMPLAINT_TYPE } from "../containers/SubmitComplaint/SelectComplaintType";
import { LanguageEnum } from "../components/SelectService/SelectService";

/**
 * * ****************************** INTERFACES *****************************
 */

export enum ComplaintStatus {
  PENDING = "PENDING",
  PROGRESS = "PROGRESS",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
}

export interface ServiceItem {
  service_category_id: string;
  kiny_service_category: string;
  eng_service_category: string;
  fr_service_category: string;
  service_id: string;
  unit_id: string;
  kiny_service: string;
  eng_service: string;
  fr_service: string;
  rwf_cost: string;
  usd_cost: string;
  duration: string;
  is_product: BooleanEnum;
}
export interface ProductComplaintDetailsInterface {
  product_complaint_id: string;
  customer_id: string;
  document_version_id: string;
  service_id: string;
  service_category_id: string;
  unit_id: string;
  short_reason: string;
  description: string;
  product_image_name: string | null;
  country_code: string | null;
  district_code: string | null;
  complaint_tracking_code: string;
  created_at: string;
  complaint_status: ComplaintStatus;
  progress_reason: string | null;
  progress_date: string | null;
  completed_reason: string | null;
  completed_date: string | null;
  rejected_reason: string | null;
  rejected_date: string | null;
  customer_feedback: string | null;
  customer_feedback_date: string | null;
  names: string | null;
  phone_number: string | null;
  email: string | null;
  gender: string | null;
  age: string | null;
  kiny_service: string | null;
  eng_service: string | null;
  fr_service: string | null;
  kiny_service_category: string;
  eng_service_category: string;
  fr_service_category: string;
  product_name: string;
  progress_description: string | null;
  completed_description: string | null;
  rejected_description: string | null;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
}

export interface SubmitServiceFormData {
  document_version_id: string;
  short_reason: number;
  service_id: string;
  service_render_date: string;
  rfd_offer_name: string;
  description: string;
  support_doc_name: File | null;
  country_code: string | null;
  district_code: string | null;
  service_category_id: string;
  unit_id: string;
  complaint_lang: LanguageEnum;
  communication_channel_id: string;
  submitted_by: string | null;
}

export interface SubmitProductFormData {
  service_id: string;
  short_reason: number;
  product_name: string;
  description: string;
  product_image_name: File | null;
  country_code: string | null;
  district_code: string | null;
  unit_id: string;
  service_category_id: string;
  complaint_lang: LanguageEnum;
  communication_channel_id: string;
  submitted_by: string | null;
}

export interface ServiceComplaintType {
  complaint_reason_id: string;
  category: COMPLAINT_TYPE;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
}

export interface CommunicationChannelItem {
  communication_channel_id: string;
  communication_channel: string;
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface GetServicesAction {
  type: ActionTypes.GET_SERVICES;
  payload: ServiceItem[];
}

export interface GetServiceComplaintTypesAction {
  type: ActionTypes.GET_SERVICE_COMPLAINT_TYPES;
  payload: ServiceComplaintType[];
}

export interface ServicesStore {
  services: ServiceItem[] | null;
  serviceComplaintTypes: ServiceComplaintType[] | null;
}

/**
 * * ****************************** ACTIONS *****************************
 */
/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */

export const FC_GetServiceComplaintTypes = (
  callback: (loading: boolean) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<ServiceComplaintType[]>(
        `${API_URL}/complaints/reason`
      );

      console.log({ service_complaint_types: res.data });

      dispatch<GetServiceComplaintTypesAction>({
        type: ActionTypes.GET_SERVICE_COMPLAINT_TYPES,
        payload: res.data,
      });
      callback(false);
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false);
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllServices = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<ServiceItem[]>(`${API_URL}/services`);

      console.log({ services_list: res.data });

      dispatch<GetServicesAction>({
        type: ActionTypes.GET_SERVICES,
        payload: res.data,
      });
      callback(false);
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false);
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitServiceComplaint = (
  data: SubmitServiceFormData,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      data: {
        customer_id: string;
        message: string;
        service_complaint_id: string;
        complaint_tracking_code: string;
      } | null;
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    const formData = new FormData();
    data.country_code !== null &&
      formData.append("country_code", data.country_code);
    formData.append("description", data.description);
    data.district_code !== null &&
      formData.append("district_code", data.district_code);
    formData.append("document_version_id", data.document_version_id);
    formData.append("rfd_offer_name", data.rfd_offer_name);
    formData.append("service_id", data.service_id);
    data.service_render_date !== "" &&
      formData.append("service_render_date", data.service_render_date);
    formData.append("short_reason", data.short_reason.toString());
    formData.append("service_category_id", data.service_category_id);
    formData.append("unit_id", data.unit_id);
    data.support_doc_name !== null &&
      formData.append("support_doc_name", data.support_doc_name);
    formData.append("complaint_lang", data.complaint_lang);
    formData.append("communication_channel_id", data.communication_channel_id);
    data.submitted_by !== null &&
      formData.append("submitted_by", data.submitted_by);

    try {
      const res = await axios.post<{
        customer_id: string;
        message: string;
        service_complaint_id: string;
        complaint_tracking_code: string;
      }>(`${API_URL}/complaints/service`, formData);
      console.log({ submit_service_complaint: res.data });
      callback(false, {
        data: {
          customer_id: res.data.customer_id,
          message: res.data.customer_id,
          service_complaint_id: res.data.service_complaint_id,
          complaint_tracking_code: res.data.complaint_tracking_code,
        },
        msg: "Query Submitted Successfully",
        type: "SUCCESS",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Query Submitted Successfully",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        data: null,
        msg: errorToText(error),
        type: "ERROR",
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitProductComplaint = (
  data: SubmitProductFormData,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      data: {
        customer_id: string;
        message: string;
        product_complaint_id: string;
        complaint_tracking_code: string;
      } | null;
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    const formData = new FormData();
    data.country_code !== null &&
      formData.append("country_code", data.country_code);
    formData.append("description", data.description);
    data.district_code !== null &&
      formData.append("district_code", data.district_code);
    formData.append("service_id", data.service_id);
    formData.append("product_name", data.product_name);
    formData.append("short_reason", data.short_reason.toString());
    formData.append("service_category_id", data.service_category_id);
    formData.append("unit_id", data.unit_id);
    data.product_image_name !== null &&
      formData.append("product_image_name", data.product_image_name);
    formData.append("complaint_lang", data.complaint_lang);
    formData.append("communication_channel_id", data.communication_channel_id);
    data.submitted_by !== null &&
      formData.append("submitted_by", data.submitted_by);
    try {
      const res = await axios.post<{
        customer_id: string;
        message: string;
        product_complaint_id: string;
        complaint_tracking_code: string;
      }>(`${API_URL}/complaints/product`, formData);
      console.log({ submit_service_complaint: res.data });
      callback(false, {
        data: {
          customer_id: res.data.customer_id,
          message: res.data.customer_id,
          product_complaint_id: res.data.product_complaint_id,
          complaint_tracking_code: res.data.complaint_tracking_code,
        },
        msg: "Query Submitted Successfully",
        type: "SUCCESS",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Query Submitted Successfully",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        data: null,
        msg: errorToText(error),
        type: "ERROR",
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetServiceComplaintDetailsByTackingCode = (
  trackingCode: string,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ServiceComplaintDetails[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ServiceComplaintDetails[]>(
        `${API_URL}/complaints/service/code/${trackingCode}`
      );
      console.log({ complaint_details_by_tracking_code: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetProductComplaintDetailsByTackingCode = (
  trackingCode: string,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: ProductComplaintDetailsInterface[];
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<ProductComplaintDetailsInterface[]>(
        `${API_URL}/complaints/product/code/${trackingCode}`
      );
      console.log({ product_complaint_details_by_tracking_code: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "",
        data: res.data,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitServiceComplaintFeedback = (
  data: {
    customer_feedback: string;
    service_complaint_id: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${API_URL}/complaints/service/feedback`,
        data
      );
      console.log({ submit_feedback: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "Your Feedback has been submitted successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Your Feedback has been submitted successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SubmitProductComplaintFeedback = (
  data: {
    customer_feedback: string;
    product_complaint_id: string;
  },
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.patch(
        `${API_URL}/complaints/product/feedback`,
        data
      );
      console.log({ submit_feedback: res.data });
      callback(false, {
        type: "SUCCESS",
        msg: "Your Feedback has been submitted successfully!",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Your Feedback has been submitted successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
