import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  ComplaintStatus,
  FC_AddSocketComplaint,
  FC_GetLocationsDetails,
  FC_GetServiceComplaintTypes,
  FC_SubmitServiceComplaint,
  LocationAPI,
  ServiceComplaintType,
  ServiceItem,
  ServicesStore,
  SocketComplaintData,
  SubmitServiceFormData,
  System,
} from "../../actions";
import { connect } from "react-redux";
import Input from "../../components/Fragments/Input";
import Textarea from "../../components/Fragments/Textarea";
import {
  DragDropFile,
  RejectedFileInterface,
} from "../../components/FileUpload/FileUpload";
import SelectCustom from "../../components/SelectCustom/SelectCustom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { ImCheckboxUnchecked } from "react-icons/im";
import { LanguageEnum } from "../../components/SelectService/SelectService";
import { COMPLAINT_TYPE } from "./SelectComplaintType";
import { GenerateLangText } from "../../utils/functions";

interface ServiceComplaintFormProps {
  auth: Auth;
  services: ServicesStore;
  selectedService: ServiceItem;
  system: System;
  language: LanguageEnum;
  FC_SubmitServiceComplaint: (
    data: SubmitServiceFormData,
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        data: {
          customer_id: string;
          message: string;
          service_complaint_id: string;
          complaint_tracking_code: string;
        } | null;
        msg: string;
      } | null
    ) => void
  ) => void;
  previewDoc: File | null;
  setPreviewDoc: (file: File) => void;
  FC_GetServiceComplaintTypes: (callback: (loading: boolean) => void) => void;
  onSubmitted: (data: {
    customer_id: string;
    message: string;
    service_complaint_id: string;
    complaint_tracking_code: string;
  }) => void;
  submitted_by: string | null;
  communication_channel: {
    communication_channel_id: string;
    communication_channel: string;
  };
  onGoBack: () => void;
  FC_AddSocketComplaint: (data: SocketComplaintData) => void;
}
interface ServiceComplaintFormState {
  success: string;
  error: {
    target:
      | "main"
      | "fda_officer_name"
      | "date_service_rendered"
      | "description"
      | "selectedReason";
    msg: string;
  } | null;
  fda_officer_name: string;
  date_service_rendered: string;
  loading: boolean;
  showSelectCountry: boolean;
  showSelectDistrict: boolean;
  selectedCountry: { country_code: string; country_name: string } | null;
  selectedDistrict: { district_code: string; district_name: string } | null;
  locations_loading: boolean;
  locations: LocationAPI | null;
  showSelectLocations: boolean;
  support_doc_name: { file: File; preview: string } | null;
  description: string;
  openSelectReason: boolean;
  selectedReason: ServiceComplaintType | null;
  complaints_loading: boolean;
  acceptedTerms: boolean;
}

class _ServiceComplaintForm extends Component<
  ServiceComplaintFormProps,
  ServiceComplaintFormState
> {
  constructor(props: ServiceComplaintFormProps) {
    super(props);
    this.state = {
      success: "",
      error: null,
      fda_officer_name: "",
      date_service_rendered: "",
      loading: false,
      showSelectCountry: false,
      showSelectDistrict: false,
      selectedCountry: {
        country_code: "RWA",
        country_name: "Rwanda",
      },
      selectedDistrict: {
        district_name: "Gasabo",
        district_code: "12",
      },
      locations_loading: false,
      locations: null,
      showSelectLocations: false,
      support_doc_name: null,
      description: "",
      openSelectReason: false,
      selectedReason: null,
      complaints_loading: false,
      acceptedTerms: false,
    };
  }
  GetLocations = () => {
    this.setState({ locations_loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        if (res?.type === "Success") {
          this.setState({
            locations_loading: loading,
            locations: res.data,
            error: null,
          });
        }
        if (res?.type === "Error") {
          this.setState({
            locations_loading: false,
            error: { target: "main", msg: res.msg },
          });
        }
      }
    );
  };
  SubmitServiceComplaint = () => {
    if (this.state.selectedReason === null) {
      return this.setState({
        error: {
          target: "selectedReason",
          msg: "Please choose complaint type",
        },
      });
    }
    if (this.state.description === "") {
      return this.setState({
        error: {
          target: "description",
          msg: "Please describe your complaint",
        },
      });
    }
    if (this.state.acceptedTerms === false) {
      return this.setState({
        error: {
          target: "main",
          msg: "Please accept terms and conditions",
        },
      });
    }
    this.props.FC_SubmitServiceComplaint(
      {
        country_code:
          this.state.selectedCountry === null
            ? null
            : this.state.selectedCountry.country_code,
        description: this.state.description,
        district_code:
          this.state.selectedDistrict === null
            ? null
            : this.state.selectedDistrict.district_code,
        document_version_id: "", //To be removed
        rfd_offer_name: this.state.fda_officer_name,
        service_id: this.props.selectedService.service_id,
        service_render_date: this.state.date_service_rendered,
        short_reason: parseInt(this.state.selectedReason.complaint_reason_id),
        support_doc_name:
          this.state.support_doc_name === null
            ? null
            : this.state.support_doc_name.file,
        service_category_id: this.props.selectedService.service_category_id,
        unit_id: this.props.selectedService.unit_id,
        complaint_lang: this.props.language,
        communication_channel_id:
          this.props.communication_channel.communication_channel_id,
        submitted_by: this.props.submitted_by,
      },
      (
        loading: boolean,
        res: {
          type: "SUCCESS" | "ERROR";
          data: {
            customer_id: string;
            message: string;
            service_complaint_id: string;
            complaint_tracking_code: string;
          } | null;
          msg: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.state.selectedReason !== null &&
            this.props.FC_AddSocketComplaint({
              complaint_status: ComplaintStatus.PENDING,
              reason_id: this.state.selectedReason.complaint_reason_id,
              unit_id: this.props.selectedService.unit_id,
              complaint_reason_en:
                this.state.selectedReason.complaint_reason_en,
            });
          this.setState({ error: null, success: res.msg, loading: false });
          setTimeout(() => {
            res.data !== null && this.props.onSubmitted(res.data);
          }, 1000);
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: { target: "main", msg: res.msg },
            loading: false,
          });
        }
      }
    );
  };
  componentDidMount(): void {
    // if (this.state.locations === null) {
    //   this.GetLocations();
    // }
    if (this.props.services.serviceComplaintTypes === null) {
      this.props.FC_GetServiceComplaintTypes((loading: boolean) =>
        this.setState({ complaints_loading: loading })
      );
    }
  }
  render() {
    return (
      <div className="p-3">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <div className="">
              {/* <div className="mb-2">
                <div className="text-sm">Service category</div>
                <div className="font-bold">
                  {this.props.language === LanguageEnum.ENG
                    ? this.props.selectedService.eng_service_category
                    : this.props.language === LanguageEnum.FR
                    ? this.props.selectedService.fr_service_category
                    : this.props.selectedService.kiny_service_category}
                </div>
              </div> */}
              <div>
                <div className="text-sm">
                  {GenerateLangText(
                    {
                      eng: "Selected service",
                      fr: "Prestation sélectionnée",
                      kiny: "Serivisi yatoranijwe",
                    },
                    this.props.language
                  )}
                </div>
                <div className="font-bold px-3 py-2 rounded-md border w-full text-sm mt-2 bg-gray-100">
                  {this.props.language === LanguageEnum.ENG
                    ? this.props.selectedService.eng_service
                    : this.props.language === LanguageEnum.FR
                    ? this.props.selectedService.fr_service
                    : this.props.selectedService.kiny_service}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className="relative z-50">
              <div className="flex flex-row items-center gap-1">
                <div className="text-sm pb-2">
                  {GenerateLangText(
                    {
                      eng: "Choose query reason",
                      fr: "Choisissez la raison de la requête",
                      kiny: "Hitamo impamvu ushaka kubaza ikibazo | gutanga icyifuzo",
                    },
                    this.props.language
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <div className="text-sm text-red-500 flex flex-row items-start">
                    (<span className="text-xl font-bold">*</span>)
                  </div>
                </div>
              </div>
              {this.state.openSelectReason === false &&
                this.state.complaints_loading === false && (
                  <div
                    onClick={() => this.setState({ openSelectReason: true })}
                    className={`flex flex-row items-center justify-between px-3 py-2 rounded-lg bg-white border-2 ${
                      this.state.error?.target === "selectedReason"
                        ? "border-red-500 animate__animated animate__shakeX"
                        : "border-primary-700"
                    } w-full cursor-pointer`}
                  >
                    {this.state.selectedReason === null ? (
                      <span className="text-gray-400">
                        {GenerateLangText(
                          {
                            eng: "Click to choose query reason",
                            fr: "Choisissez la raison de la requête",
                            kiny: "Kanda kugirango uhitemo impmavu",
                          },
                          this.props.language
                        )}
                      </span>
                    ) : (
                      <span className="font-bold">
                        {GenerateLangText(
                          {
                            eng: this.state.selectedReason.complaint_reason_en,
                            fr: this.state.selectedReason.complaint_reason_fr,
                            kiny: this.state.selectedReason
                              .complaint_reason_kiny,
                          },
                          this.props.language
                        )}
                      </span>
                    )}
                    <div>
                      <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                    </div>
                  </div>
                )}
              {this.state.error?.target === "selectedReason" && (
                <div className="py-2">
                  <Alert
                    alertType={AlertType.DANGER}
                    title={this.state.error.msg}
                    close={() => this.setState({ error: null })}
                  />
                </div>
              )}
              {this.state.complaints_loading === true ? (
                <div className="flex flex-row items-center gap-3 w-full my-2">
                  <div>
                    <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                  </div>
                  <div className="animate__animated animate__fadeIn animate__infinite text-yellow-500">
                    {GenerateLangText(
                      {
                        eng: "Loading Complaints",
                        fr: "Chargement des plaintes",
                        kiny: "Tegereza gato",
                      },
                      this.props.language
                    )}
                  </div>
                </div>
              ) : (
                this.state.openSelectReason === true && (
                  <div className="absolute left-0 right-0">
                    <SelectCustom
                      loading={
                        this.state.loading === true ??
                        this.state.complaints_loading
                      }
                      id={"complaint_reason_id"}
                      title={GenerateLangText(
                        {
                          eng: "complaint_reason_en",
                          fr: "complaint_reason_fr",
                          kiny: "complaint_reason_kiny",
                        },
                        this.props.language
                      )}
                      close={() => this.setState({ openSelectReason: false })}
                      data={
                        this.props.services.serviceComplaintTypes === null
                          ? []
                          : this.props.services.serviceComplaintTypes.filter(
                              (itm) => itm.category === COMPLAINT_TYPE.SERVICE
                            )
                      }
                      click={(data: ServiceComplaintType) => {
                        this.setState({
                          openSelectReason: false,
                          selectedReason: {
                            category: COMPLAINT_TYPE.SERVICE,
                            complaint_reason_en: data.complaint_reason_en,
                            complaint_reason_fr: data.complaint_reason_fr,
                            complaint_reason_kiny: data.complaint_reason_kiny,
                            complaint_reason_id: data.complaint_reason_id,
                          },
                        });
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div className="col-span-12 md:col-span-6">
            <Input
              title={GenerateLangText(
                {
                  eng: "Name of Rwanda FDA Officer involved (Optional)",
                  fr: "Nom de l'agent de la FDA du Rwanda impliqué (facultatif)",
                  kiny: "Izina ry'umukozi wa Rwanda FDA (Si ngombwa)",
                },
                this.props.language
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ fda_officer_name: e.target.value, error: null })
              }
              disabled={false}
              value={this.state.fda_officer_name}
              error={
                this.state.error?.target === "fda_officer_name"
                  ? this.state.error.msg
                  : ""
              }
              type={"text"}
              className="bg-gray-100 font-bold"
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <Input
              title={GenerateLangText(
                {
                  eng: "Date service was rendered (Optional)",
                  fr: "Date du service rendu (facultatif)",
                  kiny: "Igihe serivisi yatangiwe (Si ngombwa)",
                },
                this.props.language
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({
                  date_service_rendered: e.target.value,
                  error: null,
                })
              }
              disabled={false}
              value={this.state.date_service_rendered}
              error={
                this.state.error?.target === "date_service_rendered"
                  ? this.state.error.msg
                  : ""
              }
              type={"date"}
              className="bg-gray-100 font-bold"
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <Textarea
              title={GenerateLangText(
                {
                  eng: "Query details",
                  fr: "Détails de la requête",
                  kiny: "Sobanura Ikibazo/Icyifuzo",
                },
                this.props.language
              )}
              onChange={(e) =>
                this.setState({
                  description: e.target.value,
                  error: null,
                })
              }
              disabled={false}
              value={this.state.description}
              error={
                this.state.error?.target === "description"
                  ? this.state.error.msg
                  : ""
              }
              className="h-24 bg-gray-100 font-bold"
              requiredStar={true}
            />
          </div>
          <div className="col-span-12 md:col-span-6 pt-2">
            <div className="text-sm">
              {GenerateLangText(
                {
                  eng: "Supporting document (Image/PDF) Max size: 1MB (Optional)",
                  fr: "Document justificatif (Image/PDF) Taille maximale : 1 Mo (facultatif)",
                  kiny: "Inyandiko ishyigikira ikibazo (Ishusho / PDF) Ingano nini: 1MB (Si ngombwa)",
                },
                this.props.language
              )}
            </div>
            {this.state.support_doc_name === null ? (
              <DragDropFile
                className={
                  "p-4 py-5 rounded-md border-2 border-dashed text-center cursor-pointer bg-gray-100 border-gray-400 hover:bg-primary-50 hover:text-primary-900 hover:border-primary-700 animate__animated animate__fadeIn animate__slow"
                }
                allowedFileTypes={{ "application/pdf": [], "image/*": [] }}
                acceptedFiles={
                  this.state.support_doc_name === null
                    ? []
                    : [this.state.support_doc_name]
                }
                rejectedFiles={[]}
                setAcceptedFiles={(
                  acceptedFiles: { file: File; preview: string }[]
                ) => {
                  if (acceptedFiles.length > 0) {
                    this.setState({ support_doc_name: acceptedFiles[0] });
                  }
                }}
                setRejectedFiles={(files: RejectedFileInterface[]) => {
                  if (files.length > 0) {
                    this.setState({
                      error: {
                        target: "main",
                        msg: files[0].errors[0].message,
                      },
                    });
                  }
                }}
                disabled={this.state.loading}
                multiple={false}
                language={this.props.language}
              />
            ) : (
              <div className="flex flex-row justify-between gap-2 rounded-md bg-primary-50 my-2 px-3 py-3">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <HiOutlineDocumentText className="text-primary-700 text-5xl" />
                  </div>
                  <div>
                    <div className="font-bold">
                      {GenerateLangText(
                        {
                          eng: "Supporting Document",
                          fr: "Pièce justificative",
                          kiny: "ifoto cyangwa document igaragaza ikibazo",
                        },
                        this.props.language
                      )}
                    </div>
                    <div className="text-sm">
                      {this.state.support_doc_name.file.name}
                    </div>
                    <div
                      onClick={() =>
                        this.state.support_doc_name !== null &&
                        this.props.setPreviewDoc(
                          this.state.support_doc_name.file
                        )
                      }
                      className="cursor-pointer underline text-primary-700 text-sm hover:text-green-600 font-bold"
                    >
                      {GenerateLangText(
                        {
                          eng: "Click to preview document",
                          fr: "Cliquez pour prévisualiser le document",
                          kiny: "Kanda kugirango urebe ifoto cyangwa document",
                        },
                        this.props.language
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => this.setState({ support_doc_name: null })}
                    className="px-3 py-2 rounded-md bg-red-50 hover:bg-red-600 hover:text-white cursor-pointer text-sm font-bold w-max border border-red-400 hover:border-white"
                  >
                    {GenerateLangText(
                      {
                        eng: "Remove",
                        fr: "Retirer",
                        kiny: "Gusiba",
                      },
                      this.props.language
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-12">
            <div className="bg-gray-100 rounded-md">
              <div className="grid grid-cols-12 gap-4 pb-3 px-3">
                <div className="col-span-12 pt-3">
                  <div
                    className={`bg-white border ${
                      this.state.acceptedTerms === true
                        ? "border-green-500"
                        : "border-yellow-400"
                    } px-3 pl-2 py-2 flex flex-row items-center gap-2 rounded-md`}
                  >
                    <div
                      onClick={() =>
                        this.setState({
                          acceptedTerms: !this.state.acceptedTerms,
                        })
                      }
                      className="cursor-pointer group"
                    >
                      {this.state.acceptedTerms === true ? (
                        <BsFillCheckSquareFill className="text-3xl text-green-500 animate__animated animate__bounceIn" />
                      ) : (
                        <ImCheckboxUnchecked className="text-3xl text-red-500 group-hover:text-primary-700 animate__animated animate__fadeIn" />
                      )}
                    </div>
                    <div className="text-base">
                      {GenerateLangText(
                        {
                          eng: "I hereby acknowledge and accept that the information provided is correct.",
                          fr: "Je reconnais et accepte par la présente que les informations fournies sont correctes.",
                          kiny: "Ndemera ko amakuru yatanzwe ari ukuri.",
                        },
                        this.props.language
                      )}
                    </div>
                  </div>
                </div>
                {this.state.error?.target === "main" && (
                  <div className="col-span-12">
                    <Alert
                      alertType={AlertType.DANGER}
                      title={this.state.error.msg}
                      close={() => this.setState({ error: null, success: "" })}
                    />
                  </div>
                )}
                {this.state.success !== "" && (
                  <div className="col-span-12">
                    <Alert
                      alertType={AlertType.SUCCESS}
                      title={this.state.success}
                      close={() => this.setState({ error: null, success: "" })}
                    />
                  </div>
                )}
                <div className="col-span-12 flex flex-row items-center justify-end gap-2">
                  <div
                    onClick={this.props.onGoBack}
                    className="bg-white hover:bg-red-600 hover:text-white px-4 py-2 font-bold w-max rounded-md cursor-pointer"
                  >
                    {GenerateLangText(
                      {
                        eng: "Cancel",
                        fr: "Annuler",
                        kiny: "Guhagarika igikorwa",
                      },
                      this.props.language
                    )}
                  </div>
                  <div
                    onClick={() => this.SubmitServiceComplaint()}
                    className="bg-green-600 text-white hover:bg-green-700 px-4 py-2 font-bold w-max rounded-md cursor-pointer flex items-center justify-center gap-2"
                  >
                    {this.state.loading === true && (
                      <div>
                        <AiOutlineLoading3Quarters className="animate-spin text-2xl text-yellow-300" />
                      </div>
                    )}
                    {this.state.loading === false ? (
                      <span>
                        {GenerateLangText(
                          {
                            eng: "Save & Continue",
                            fr: "Enregistrer & Continuer",
                            kiny: "Ohereza ikibazo & ukomeze",
                          },
                          this.props.language
                        )}
                      </span>
                    ) : (
                      <span className="animate__animated animate__fadeIn animate__infinite">
                        {GenerateLangText(
                          {
                            eng: "Saving Changes...",
                            fr: "Enregistrer les modifications...",
                            kiny: "Tegereza gato...",
                          },
                          this.props.language
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
  services,
}: StoreState): { auth: Auth; system: System; services: ServicesStore } => {
  return { auth, system, services };
};

export const ServiceComplaintForm = connect(mapStateToProps, {
  FC_SubmitServiceComplaint,
  FC_GetServiceComplaintTypes,
  FC_AddSocketComplaint,
})(_ServiceComplaintForm);
