import React, { Component } from "react";
import { BooleanEnum, ServiceItem, ServicesStore, System } from "../../actions";
import { FiSearch } from "react-icons/fi";
import { IoIosArrowDown } from "react-icons/io";
import { BsCheckCircleFill } from "react-icons/bs";
import LoaderComponent from "../Loading/LoaderComponent";
import { NoResultFound } from "../Fragments/NoResultFound";
import { GenerateLangText, search } from "../../utils/functions";
import { COMPLAINT_TYPE } from "../../containers/SubmitComplaint/SelectComplaintType";

export enum LanguageEnum {
  ENG = "ENG",
  FR = "FR",
  KINY = "KINY",
}

interface SelectServiceProps {
  system: System;
  services: ServicesStore;
  complaintType: COMPLAINT_TYPE | null;
  onSelectService: (service: ServiceItem) => void;
  selectedService: ServiceItem | null;
  onSave: () => void;
  FC_ChangeSystemLanguage: (lang: LanguageEnum) => void;
  FC_GetAllServices: (callback: (loading: boolean) => void) => void;
  language: LanguageEnum;
}
interface SelectServiceState {
  openSelectLanguage: boolean;
  loading: boolean;
  searchData: string;
  showContinueButton: boolean;
}

export class SelectService extends Component<
  SelectServiceProps,
  SelectServiceState
> {
  constructor(props: SelectServiceProps) {
    super(props);
    this.state = {
      openSelectLanguage: false,
      loading: false,
      searchData: "",
      showContinueButton: false,
    };
  }
  GetServicesList = () => {
    this.setState({ loading: true });
    this.props.FC_GetAllServices((loading: boolean) =>
      this.setState({ loading: loading })
    );
  };
  componentDidMount(): void {
    if (
      this.props.services.services === null ||
      this.props.services.services.length === 0
    ) {
      this.GetServicesList();
    }
  }
  FilteredData = (): ServiceItem[] => {
    if (this.props.services.services === null) {
      return [];
    }
    var response = this.props.services.services;
    if (
      this.props.complaintType !== null &&
      this.props.complaintType === COMPLAINT_TYPE.PRODUCT
    ) {
      response = response.filter((itm) => itm.is_product === BooleanEnum.TRUE);
    }
    if (
      this.props.complaintType !== null &&
      this.props.complaintType === COMPLAINT_TYPE.SERVICE
    ) {
      response = response.filter((itm) => itm.is_product === BooleanEnum.FALSE);
    }
    return search(response, this.state.searchData);
  };
  render() {
    return (
      <div className="px-3 pt-2 relative">
        <div className="flex flex-row items-center gap-3 w-full mb-3">
          <div className="w-full relative">
            <input
              type="text"
              className="border-b border-gray-300 w-full px-3 py-2 h-12 focus:outline-none pl-12"
              placeholder={GenerateLangText(
                {
                  eng: "Search by keyword",
                  fr: "Recherche par mots-clés",
                  kiny: "Shakisha",
                },
                this.props.system.language
              )}
              value={this.state.searchData}
              onChange={(e) => this.setState({ searchData: e.target.value })}
            />
            <div className="absolute top-3 left-3">
              <FiSearch className="text-2xl" />
            </div>
          </div>
          <div className="relative">
            <div
              onClick={() =>
                this.setState({
                  openSelectLanguage: !this.state.openSelectLanguage,
                })
              }
              className="flex flex-row items-center justify-between gap-3 bg-gray-100 px-3 py-2 rounded-md cursor-pointer hover:bg-primary-100 hover:text-primary-900"
            >
              <div>{this.props.language}</div>
              <div>
                <IoIosArrowDown className="text-xl" />
              </div>
            </div>
            {/* Filter Language */}
            {this.state.openSelectLanguage === true && (
              <div className="absolute truncate right-0 pt-2 z-10">
                <div className="bg-white rounded-md border shadow-md animate__animated animate__fadeInUp animate__faster">
                  <div className="flex flex-row items-center justify-between gap-6 w-full border-b px-3 pr-1 py-1">
                    <div className="font-bold">
                      {GenerateLangText(
                        {
                          eng: "Choose language",
                          fr: "Choisissez la langue",
                          kiny: "hitamo ururimi",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div
                      onClick={() =>
                        this.setState({ openSelectLanguage: false })
                      }
                      className="p-1 px-2 rounded-md bg-red-50 hover:bg-red-100 text-red-800 cursor-pointer"
                    >
                      Close
                    </div>
                  </div>
                  <div className="p-2">
                    {[
                      { caption: "English", key: LanguageEnum.ENG },
                      { caption: "French", key: LanguageEnum.FR },
                      { caption: "Kinyarwanda", key: LanguageEnum.KINY },
                    ].map((item, i) => (
                      <div
                        className={`px-3 py-2 text-sm border ${
                          this.props.language === item.key
                            ? "border-green-400 text-green-600 animate__animated animate__fadeIn"
                            : "hover:border-primary-300 hover:bg-primary-100 hover:text-primary-900"
                        } rounded-md mb-1 cursor-pointer flex flex-row items-center gap-2`}
                        key={i + 1}
                        onClick={() => {
                          this.setState({
                            openSelectLanguage: false,
                          });
                          this.props.FC_ChangeSystemLanguage(item.key);
                        }}
                      >
                        {item.key === this.props.language && (
                          <div>
                            <BsCheckCircleFill className="text-xl text-green-500" />
                          </div>
                        )}
                        <span>{item.caption}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="pb-3 overflow-y-auto"
          style={{ height: "calc(100vh - 260px)" }}
        >
          {this.state.loading === true ||
          this.props.services.services === null ? (
            <LoaderComponent />
          ) : this.props.services.services.length === 0 ? (
            <NoResultFound
              title={GenerateLangText(
                {
                  eng: "No Service Found",
                  fr: "Aucun service trouvé",
                  kiny: "Nta Serivisi Yabonetse",
                },
                this.props.system.language
              )}
              description={GenerateLangText(
                {
                  eng: "There are no services or products available",
                  fr: "Il n'y a pas de services ou de produits disponibles",
                  kiny: "Nta serivisi cyangwa ibicuruzwa bihari",
                },
                this.props.system.language
              )}
            />
          ) : this.FilteredData().length === 0 ? (
            <NoResultFound
              title={GenerateLangText(
                {
                  eng: "No Service Found",
                  fr: "Aucun service trouvé",
                  kiny: "Nta Serivisi Yabonetse",
                },
                this.props.system.language
              )}
              description={GenerateLangText(
                {
                  eng: "There are no services or products available",
                  fr: "Il n'y a pas de services ou de produits disponibles",
                  kiny: "Nta serivisi cyangwa ibicuruzwa bihari",
                },
                this.props.system.language
              )}
            />
          ) : (
            this.FilteredData().map((item, i) => (
              <div
                key={i + 1}
                className={`flex flex-row items-center gap-2 ${
                  this.props.selectedService?.service_id === item.service_id
                    ? "border-green-300 bg-green-50 text-green-600"
                    : "border-white hover:border-gray-200 hover:bg-gray-100"
                } border px-2 py-1 rounded-lg pl-1 cursor-pointer group animate__animated animate__fadeIn z-0`}
                onClick={() => {
                  this.props.onSelectService(item);
                  this.setState({ showContinueButton: true });
                }}
              >
                <div>
                  <div
                    className={`h-9 w-9 flex items-center justify-center border ${
                      this.props.selectedService?.service_id === item.service_id
                        ? "bg-white text-green-600 border-white"
                        : "group-hover:border-gray-300 group-hover:bg-white group-hover:text-gray-800"
                    } rounded-full`}
                  >
                    {this.props.selectedService?.service_id ===
                    item.service_id ? (
                      <div>
                        <BsCheckCircleFill className="text-3xl text-green-500 rounded-full animate__animated animate__bounceIn" />
                      </div>
                    ) : (
                      <span className="text-gray-500">{i + 1}</span>
                    )}
                  </div>
                </div>
                <div>
                  {this.props.language === LanguageEnum.ENG && item.eng_service}
                  {this.props.language === LanguageEnum.FR && item.fr_service}
                  {this.props.language === LanguageEnum.KINY &&
                    item.kiny_service}
                </div>
              </div>
            ))
          )}
        </div>
        {/* {this.state.showContinueButton === true && (
          <div
            onClick={() => this.props.onSave()}
            className="bg-green-600 text-white px-3 py-2 font-bold w-max absolute bottom-4 right-4 shadow-xl rounded-md animate__animated animate__bounceIn cursor-pointer"
          >
            Select & Continue
          </div>
        )} */}
      </div>
    );
  }
}
