import {
  CleanUserDetails,
  LoginSuccessDetails,
  LogoutUser,
} from "./auth.action";
import {
  GetServiceComplaintTypesAction,
  GetServicesAction,
} from "./services.action";
import {
  AddSocketComplaintDataAction,
  GetSocketComplaintDataAction,
} from "./socket.action";
import {
  ChangeSystemLanguageAction,
  GetAccessListDetailsInfoAction,
  GetCommunicationChannelsAction,
  GetFrequentlyAskedQuestionsInfoAction,
  GetSystemInfoAction,
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { GetAllUnitsInfoAction } from "./units.action";

export enum ActionTypes {
  LOGIN_DETAILS = "LOGIN_DETAILS",
  USER_LOGIN_SUCCESS_DATA = "USER_LOGIN_SUCCESS_DATA",
  CLEAN_USER_DETAILS = "CLEAN_USER_DETAILS",
  LOGOUT = "LOGOUT",
  GET_SYSTEM_BASIC_INFO = "GET_SYSTEM_BASIC_INFO",
  SET_SYSTEM_ERROR_MESSAGE = "SET_SYSTEM_ERROR_MESSAGE",
  SET_SYSTEM_SUCCESS_MESSAGE = "SET_SYSTEM_SUCCESS_MESSAGE",

  GET_ALL_UNITS = "GET_ALL_UNITS",
  GET_ALL_ACCESS_DETAILS = "GET_ALL_ACCESS_DETAILS",
  UPDATE_POSITION_ACCESS = "UPDATE_POSITION_ACCESS",
  GET_ACADEMIC_BASICS = "GET_ACADEMIC_BASICS",
  UPDATE_PERSONAL_INFO = "UPDATE_PERSONAL_INFO",
  CHANGE_SYSTEM_LANGUAGE = "CHANGE_SYSTEM_LANGUAGE",
  GET_SERVICES = "GET_SERVICES",
  GET_SERVICE_COMPLAINT_TYPES = "GET_SERVICE_COMPLAINT_TYPES",
  GET_FAQ_ACTION = "GET_FAQ_ACTION",
  GET_COMMUNICATION_CHANNELS = "GET_COMMUNICATION_CHANNELS",
  ADD_SOCKET_COMPLAINT_DATA = "ADD_SOCKET_COMPLAINT_DATA",
  GET_SOCKET_COMPLAINT_DATA = "GET_SOCKET_COMPLAINT_DATA",
}

export type Action =
  | CleanUserDetails
  | LoginSuccessDetails
  | LogoutUser
  | GetSystemInfoAction
  | SetSystemErrorMessageAction
  | SetSystemSuccessMessageAction
  | GetAllUnitsInfoAction
  | GetAccessListDetailsInfoAction
  | ChangeSystemLanguageAction
  | GetServicesAction
  | GetServiceComplaintTypesAction
  | GetFrequentlyAskedQuestionsInfoAction
  | GetCommunicationChannelsAction
  | AddSocketComplaintDataAction
  | GetSocketComplaintDataAction;
