import React, { Component } from "react";
import { AiOutlineLogout, AiOutlineMenu } from "react-icons/ai";
import FDA_LOGO from "../../assets/logo.jpeg";
import { FaUserCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Auth } from "../../actions";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import { TbArrowsDiagonalMinimize2 } from "react-icons/tb";
import { SiWheniwork } from "react-icons/si";
import { HiOutlineBriefcase } from "react-icons/hi";
import { LanguageEnum } from "../SelectService/SelectService";
import { BiWorld } from "react-icons/bi";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";
import { BsCheckCircleFill } from "react-icons/bs";
import { GenerateLangText } from "../../utils/functions";

interface NavBarProps {
  auth: Auth;
  FC_Logout: () => void;
  setOpenVav: (status: boolean) => void;
  sideNavbarStatus: boolean;
  SwitchEmployment: () => void;
  language: LanguageEnum;
  onChangeLanguage: (language: LanguageEnum) => void;
}
interface NavBarState {
  view_user: boolean;
  loading: boolean;
  showChooseLanguage: boolean;
}

export class NavBar extends Component<NavBarProps, NavBarState> {
  constructor(props: NavBarProps) {
    super(props);

    this.state = {
      loading: false,
      view_user: false,
      showChooseLanguage: false,
    };
  }
  componentDidMount(): void {}
  render() {
    return (
      <div>
        <div
          className={`${
            this.props.auth.isAuthenticated === false
              ? "bg-white"
              : " bg-primary-800 text-white"
          } py-1 pl-3 fixed top-0 right-0 left-0 shadow-md z-50`}
        >
          <div
            className={`${
              this.props.auth.isAuthenticated === false
                ? "container mx-auto lg:px-10"
                : ""
            }`}
          >
            <div className="flex flex-row items-center justify-between gap-3">
              <div className="flex flex-row items-center gap-2">
                {this.props.auth.isAuthenticated === false && (
                  <div className="h-14 w-12">
                    <img
                      className="h-14 w-12"
                      src={FDA_LOGO}
                      alt="Valuation Management System"
                    />
                  </div>
                )}

                {this.props.auth.isAuthenticated === false ? (
                  <div className="flex flex-row items-center gap-2">
                    <div className="text-black py-4 font-extrabold text-lg hidden md:block">
                      CUSTOMER
                    </div>
                    <div className="px-2 rounded-lg bg-green-600 text-white w-max font-bold">
                      SUPPORT
                    </div>
                  </div>
                ) : (
                  <div className="my-2 flex flex-row items-center gap-3">
                    <div
                      onClick={() =>
                        this.props.setOpenVav(!this.props.sideNavbarStatus)
                      }
                      className="bg-primary-700 rounded-md p-2 cursor-pointer hover:bg-primary-900"
                    >
                      {this.props.sideNavbarStatus === true ? (
                        <TbArrowsDiagonalMinimize2 className="text-2xl text-primary-100 animate__animated animate__zoomIn" />
                      ) : (
                        <AiOutlineMenu className="text-2xl text-primary-100 animate__animated animate__fadeIn" />
                      )}
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2 text-lg rounded-full w-max pr-3 cursor-pointer group">
                        <div>
                          <SiWheniwork className="text-3xl text-primary-300 group-hover:text-yellow-300" />
                        </div>
                        <span className="text-white font-bold">
                          {this.props.auth.selectedEmployment?.position_name}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                {this.props.auth.isAuthenticated === true ? (
                  <div className="flex flex-row items-center gap-2 justify-end mr-2">
                    {/* User icon */}
                    <div className="relative">
                      <div
                        onClick={() =>
                          this.setState({ view_user: !this.state.view_user })
                        }
                        className={`rounded-full flex items-center justify-center ${
                          this.state.view_user === true
                            ? "bg-red-600 text-white border-2 border-white"
                            : "bg-primary-700 hover:bg-primary-900 text-white"
                        }  h-10 w-10  cursor-pointer`}
                      >
                        {this.state.view_user === false ? (
                          <FaUserCircle className="text-4xl animate__animated animate__fadeIn" />
                        ) : (
                          <IoMdClose className="text-3xl animate__animated animate__fadeIn" />
                        )}
                      </div>
                      {this.state.view_user === true && (
                        <div className="h-full overflow-y-auto">
                          <div
                            onClick={() => this.setState({ view_user: false })}
                            className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60"
                            title="Click here to close"
                          ></div>
                          <div className="absolute right-0 pt-0 top-1">
                            <div className="border border-gray-300 bg-white p-3 rounded-md w-64 shadow-xl animate__animated animate__fadeInRight animate__faster">
                              <div className="flex flex-col items-center justify-center w-full gap-0">
                                <div className="mt-3">
                                  <div className="rounded-full text-gray-400 flex items-center justify-center h-24 w-24 overflow-hidden">
                                    <FaUserCircle className="text-8xl" />
                                  </div>
                                </div>
                                <div className="font-bold text-center text-black mt-2">
                                  <span>
                                    {this.props.auth.user?.first_name}{" "}
                                    {this.props.auth.user?.last_name}
                                  </span>
                                </div>
                                <div className="font-bold text-center mb-4 text-white bg-primary-700 rounded-md px-2">
                                  <span className="font-normal">
                                    {this.props.auth.user?.phone_number}
                                  </span>
                                </div>
                                <div className="font-normal text-sm text-gray-500 px-3 py-2 border border-primary-700 rounded-lg w-full">
                                  <div className="text-sm font-bold text-black">
                                    My Position
                                  </div>
                                  {
                                    this.props.auth.selectedEmployment
                                      ?.position_name
                                  }
                                </div>
                                <div className="font-normal text-sm text-gray-500 px-3 py-2 mt-2 border border-primary-700 rounded-lg w-full">
                                  <div className="text-sm font-bold text-black">
                                    My Unit
                                  </div>
                                  {
                                    this.props.auth.selectedEmployment
                                      ?.unit_name
                                  }
                                </div>
                              </div>

                              <div className="mt-5 text-black">
                                <div className="text-sm text-gray-600 mt-5 mb-2">
                                  ACTION MENU
                                </div>
                                <Link
                                  onClick={() =>
                                    this.setState({ view_user: false })
                                  }
                                  to={"/profile"}
                                  className="flex flex-row items-center gap-2 bg-gray-50 rounded-md px-2 py-1 cursor-pointer hover:bg-primary-700 hover:text-white group"
                                >
                                  <div>
                                    <FaUserCircle className="text-xl text-primary-700 group-hover:text-white" />
                                  </div>
                                  <div>User Profile</div>
                                </Link>
                                {this.props.auth.user !== null &&
                                  this.props.auth.user.employment.length >
                                    0 && (
                                    <div
                                      onClick={() => {
                                        this.setState({ view_user: false });
                                        this.props.SwitchEmployment();
                                      }}
                                      className="flex flex-row items-center gap-2 bg-gray-50 rounded-md px-2 py-1 cursor-pointer hover:bg-primary-700 hover:text-white group"
                                    >
                                      <div>
                                        <HiOutlineBriefcase className="text-xl text-yellow-600 group-hover:text-white" />
                                      </div>
                                      <div>Change position</div>
                                    </div>
                                  )}
                                <Link
                                  onClick={() =>
                                    this.setState({ view_user: false })
                                  }
                                  to={"/change-password"}
                                  className="flex flex-row items-center gap-2 bg-gray-50 rounded-md px-2 py-1 cursor-pointer hover:bg-primary-700 hover:text-white group"
                                >
                                  <div>
                                    <RiLockPasswordLine className="text-xl text-red-700 group-hover:text-white" />
                                  </div>
                                  <div>Change password</div>
                                </Link>

                                <div
                                  onClick={() => {
                                    this.setState({ view_user: false });
                                    this.props.FC_Logout();
                                  }}
                                  className="flex flex-row items-center gap-2 border border-yellow-700 rounded-md px-2 py-1 cursor-pointer hover:bg-yellow-700 hover:text-white group mt-2"
                                >
                                  <div>
                                    <AiOutlineLogout className="text-xl text-gray-500 group-hover:text-white" />
                                  </div>
                                  <div>Sign out</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  // <a
                  //   href="https://rwandafda.gov.rw"
                  //   title="Rwanda FDA"
                  //   className="hover:underline hover:text-primary-800 mr-2"
                  //   target={"_blank"}
                  //   rel="noreferrer"
                  // >
                  //   Website
                  // </a>
                  <div></div>
                )}
                <NavLink
                  to="/"
                  title="Rwanda FDA"
                  className="hover:underline hover:text-primary-800 mr-3"
                >
                  {GenerateLangText(
                    {
                      eng: "Home",
                      fr: "Home",
                      kiny: "Ahabanza",
                    },
                    this.props.language
                  )}
                </NavLink>
                <NavLink
                  to="/track-complaint"
                  title="Track your query"
                  className="hover:underline hover:text-primary-800 mr-3"
                >
                  {GenerateLangText(
                    {
                      eng: "Track",
                      fr: "Suivre",
                      kiny: "Kurikirana",
                    },
                    this.props.language
                  )}
                </NavLink>
                <NavLink
                  to={{ pathname: "https://hrms.rwandafda.gov.rw/" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  title=""
                  className="hover:underline hover:text-primary-800 mr-3"
                >
                  {GenerateLangText(
                    {
                      eng: "Staff Login",
                      fr: "Staff Login",
                      kiny: "Staff Login",
                    },
                    this.props.language
                  )}
                </NavLink>
                <div className="relative">
                  <div
                    onClick={() =>
                      this.setState({
                        showChooseLanguage: !this.state.showChooseLanguage,
                      })
                    }
                    className="flex flex-row items-center justify-center gap-3 w-max px-2 py-1 pl-1 bg-white rounded-md cursor-pointer hover:bg-primary-800 hover:text-white group border hover:border-white"
                  >
                    <div className="flex flex-row items-center gap-1">
                      <div>
                        <BiWorld className="text-xl text-primary-700 group-hover:text-white" />
                      </div>
                      <div className="font-bold">{this.props.language}</div>
                    </div>
                    <div>
                      <MdKeyboardArrowDown className="text-xl" />
                    </div>
                  </div>
                  {this.state.showChooseLanguage === true && (
                    <div className="absolute bg-primary-800 text-white truncate right-0 animate__animated animate__fadeIn animate__faster w-64 rounded-md overflow-hidden">
                      <div className="px-2 pl-3 py-2 bg-primary-900 mb-2 border-b border-primary-400 flex flex-row items-center justify-between gap-2">
                        <div> Choose language</div>
                        <div>
                          <div
                            onClick={() =>
                              this.setState({ showChooseLanguage: false })
                            }
                            className="h-8 w-8 flex items-center justify-center bg-red-100 text-red-600 hover:bg-red-600 hover:text-white rounded-full cursor-pointer"
                          >
                            <MdClose className="text-xl" />
                          </div>
                        </div>
                      </div>
                      {[
                        { type: LanguageEnum.ENG, caption: "English" },
                        { type: LanguageEnum.FR, caption: "French" },
                        { type: LanguageEnum.KINY, caption: "Kinyarwanda" },
                      ].map((lang, l) => (
                        <div
                          className={`flex flex-row items-center justify-between w-full px-2 py-2 pl-3 text-sm ${
                            this.props.language === lang.type
                              ? "bg-primary-700 font-bold animate__animated animate__fadeIn"
                              : "cursor-pointer hover:bg-primary-300 hover:text-primary-900"
                          }`}
                          onClick={() => {
                            this.props.onChangeLanguage(lang.type);
                            this.setState({ showChooseLanguage: false });
                          }}
                        >
                          <div>{lang.caption}</div>
                          {this.props.language === lang.type && (
                            <div>
                              <BsCheckCircleFill className="text-2xl text-green-300 animate__animated animate__bounceIn" />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
