import React, { Component } from "react";
import { StoreState } from "../../reducers";
import { Auth, FC_SubmitServiceComplaintFeedback } from "../../actions";
import { connect } from "react-redux";
import Textarea from "../Fragments/Textarea";
import BackButton from "../Fragments/BackButton";
import { ServiceComplaintDetails } from "../../containers/TrackComplaint/TrackComplaint";
import Alert, { AlertType } from "../Alert/Alert";

interface SubmitFeedbackProps {
  selectedComplaint: ServiceComplaintDetails;
  FC_SubmitServiceComplaintFeedback: (
    data: {
      customer_feedback: string;
      service_complaint_id: string;
    },
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
      } | null
    ) => void
  ) => void;
  onBack: () => void;
  onSubmitFeedback: (
    data: {
      customer_feedback: string;
      service_complaint_id: string;
    },
    callBack: (completedChanges: boolean) => void
  ) => void;
}
interface SubmitFeedbackState {
  customer_feedback: string;
  error: { target: "main" | "customer_feedback"; msg: string } | null;
  loading: boolean;
  success: string;
}

class _SubmitFeedback extends Component<
  SubmitFeedbackProps,
  SubmitFeedbackState
> {
  constructor(props: SubmitFeedbackProps) {
    super(props);
    this.state = {
      customer_feedback: "",
      error: null,
      loading: false,
      success: "",
    };
  }
  SubmitComplaintResponseFeedback = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.customer_feedback === "") {
      return this.setState({
        error: {
          target: "customer_feedback",
          msg: "Please fill your feedback",
        },
      });
    }
    this.setState({ loading: true });
    this.props.FC_SubmitServiceComplaintFeedback(
      {
        customer_feedback: this.state.customer_feedback,
        service_complaint_id: this.props.selectedComplaint.service_complaint_id,
      },
      (
        loading: boolean,
        res: { type: "SUCCESS" | "ERROR"; msg: string } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.setState({ loading: true, success: res.msg, error: null });
          setTimeout(() => {
            this.props.onSubmitFeedback(
              {
                customer_feedback: this.state.customer_feedback,
                service_complaint_id:
                  this.props.selectedComplaint.service_complaint_id,
              },
              (completedChanges: boolean) => {
                if (completedChanges === true) {
                  this.props.onBack();
                }
              }
            );
          }, 1000);
        }
        if (res?.type === "ERROR") {
          this.setState({
            loading: false,
            success: "",
            error: { target: "main", msg: res.msg },
          });
        }
      }
    );
  };
  render() {
    return (
      <div className="bg-gray-200 rounded-md p-2 md:p-4">
        <div className="flex flex-row items-center gap-3 mb-2 pb-2 border-b border-gray-300">
          <BackButton
            title="Back"
            className="bg-white hover:bg-primary-700 hover:text-white rounded-md"
            onClick={this.props.onBack}
          />
          <div className="font-bold text-xl">Customer Feedback</div>
        </div>
        <form
          onSubmit={this.SubmitComplaintResponseFeedback}
          className="w-full"
        >
          <div>
            <Textarea
              title={"Feedback for Complaint response"}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                this.setState({
                  customer_feedback: e.target.value,
                  error: null,
                })
              }
              disabled={this.state.loading}
              value={this.state.customer_feedback}
              error={
                this.state.error?.target === "customer_feedback"
                  ? this.state.error.msg
                  : ""
              }
              className="border-2 border-primary-700 h-32"
            />
          </div>
          {this.state.error?.target === "main" && (
            <div className="py-2">
              <Alert
                alertType={AlertType.DANGER}
                title={this.state.error.msg}
                close={() => this.setState({ error: null, success: "" })}
              />
            </div>
          )}
          {this.state.success !== "" && (
            <div className="py-2">
              <Alert
                alertType={AlertType.SUCCESS}
                title={this.state.success}
                close={() => this.setState({ error: null, success: "" })}
              />
            </div>
          )}
          <div className="pt-2">
            <button
              disabled={this.state.loading}
              className={`flex flex-row items-center justify-center gap-2 ${
                this.state.loading === true
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-green-500 hover:bg-green-600"
              }  text-white rounded-md px-3 py-2`}
            >
              {this.state.loading === false ? (
                <span>Submit Feedback</span>
              ) : (
                <span className="animate__animated animate__fadeIn animate__infinite">
                  Sending your feedback...
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
}: StoreState): {
  auth: Auth;
} => {
  return { auth };
};

export const SubmitServiceFeedback = connect(mapStateToProps, {
  FC_SubmitServiceComplaintFeedback,
})(_SubmitFeedback);
