import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  FAQuestionInterface,
  FC_GetFrequentlyAskedQuestions,
  FC_GetSystemInfo,
  FC_Login,
  System,
} from "../../actions";
import { connect } from "react-redux";
import Footer from "../../components/Footer/Footer";
import {
  BsFileEarmarkPdf,
  BsInfoCircle,
  BsQuestionCircle,
} from "react-icons/bs";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import { FAQItem } from "../../components/FAQItem/FAQItem";
import { GenerateLangText } from "../../utils/functions";
import { server } from "../../utils/api";

interface AppProps {
  auth: Auth;
  system: System;
  FC_Login: (
    data: {
      username: string;
      password: string;
    },
    CallbackFunc: Function
  ) => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_GetFrequentlyAskedQuestions: (
    callback: (loading: boolean) => void
  ) => void;
}

interface AppState {
  redirect: boolean;
  username: string;
  password: string;
  loading: boolean;
  error: {
    target: string | null;
    msg: string;
  };
  passwordDisplay: boolean;
  loadFAQs: boolean;
  openedFaq: FAQuestionInterface | null;
}

class _App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      redirect: false,
      username: "",
      password: "",
      loading: false,
      error: {
        target: null,
        msg: "",
      },
      passwordDisplay: false,
      loadFAQs: false,
      openedFaq: null,
    };
  }
  componentDidMount = () => {
    if (this.props.system.FAQs === null) {
      this.setState({ loadFAQs: true });
      this.props.FC_GetFrequentlyAskedQuestions((loading: boolean) =>
        this.setState({ loadFAQs: loading })
      );
    }
  };
  render() {
    return (
      <Fragment>
        <div className="relative min-h-screen flex items-center justify-center bg-gray-200">
          {/* <div className="fixed bg-hero-pattern-home top-0 right-0 left-0 bottom-1/2 filter blur-lg"></div> */}
          <div
            className="absolute bg-hero-pattern-home top-0 right-0 left-0 h-scren mb-14 bg-cover bg-no-repeat bg-center"
            style={{ height: "430px" }}
          ></div>
          <div className="w-full z-0 pt-16 md:pt-36 h-full bg-transparent top-0 left-0 right-0 bottom-1/3 flex flex-col items-center justify-center">
            <div className="grid grid-cols-12 w-full lg:px-0">
              <div className="col-span-12 md:col-span-1 lg:col-span-1"></div>
              <div className="col-span-12 md:col-span-10 lg:col-span-10 p-4">
                <div className="text-2xl md:text-3xl font-bold text-center pb-3 pt-0 text-white">
                  {GenerateLangText(
                    {
                      eng: "HOW CAN WE HELP YOU?",
                      fr: "COMMENT POUVONS-NOUS VOUS AIDER?",
                      kiny: "NI GUTE TUGUFASHA?",
                    },
                    this.props.system.language
                  )}
                </div>
                <div className="pb-6 md:pb-14">
                  {/* <div className="relative">
                    <input
                      type="text"
                      className="bg-white rounded-full h-12 w-full pl-12 text-primary-800 font-light focus:outline-none"
                      placeholder={`${GenerateLangText(
                        {
                          eng: "Search in our knowledge base",
                          fr: "Rechercher dans notre base de connaissances",
                          kiny: "Shakisha mu bubiko bwacu",
                        },
                        this.props.system.language
                      )}`}
                    />
                    <div className="absolute top-3 left-3">
                      <FiSearch className="text-2xl text-gray-500" />
                    </div>
                  </div> */}
                </div>
                <div className="grid grid-cols-12 gap-6 w-full container mx-auto animate__animated animate__fadeIn animate__fast">
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 rounded-xl bg-white h-full p-4 md:p-6">
                    <div>
                      <BsQuestionCircle className="text-7xl text-primary-700" />
                    </div>
                    <div className="text-xl font-bold mt-3">
                      {GenerateLangText(
                        {
                          eng: "Send your Query",
                          fr: "Envoyer une réclamation",
                          kiny: "Kohereza Ikibazo/Icyifuzo",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="mt-1 font-light text-gray-500">
                      {GenerateLangText(
                        {
                          eng: "Submit your query by creating the ticket to be tracked",
                          fr: "Déposez votre réclamation en créant le ticket à suivre",
                          kiny: "Menyesha Ikibazo/Icyifuzo ufite abakozi ba Rwanda FDA bagufashe",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="pt-3">
                      <Link
                        to={"/submit-complaint"}
                        className="w-full cursor-pointer hover:bg-primary-700 hover:text-white hover:border-white rounded-full border border-primary-300 text-primary-800 font-normal px-4 pr-1 py-1 flex flex-row items-center justify-between gap-3 group"
                      >
                        <div>
                          {GenerateLangText(
                            {
                              eng: "Continue",
                              fr: "Continuer",
                              kiny: "Komeza",
                            },
                            this.props.system.language
                          )}
                        </div>
                        <div>
                          <IoIosArrowDroprightCircle className="text-3xl text-primary-300 group-hover:text-white" />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6 lg:col-span-4 rounded-xl bg-white h-full p-4 md:p-6">
                    <div>
                      <MdOutlineScreenSearchDesktop className="text-7xl text-yellow-500" />
                    </div>
                    <div className="text-xl font-bold mt-3">
                      {GenerateLangText(
                        {
                          eng: "Track your Query",
                          fr: "Suivez votre plainte",
                          kiny: "Kurikirana Ikibazo/Icyifuzo cyawe",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="mt-1 font-light text-gray-500">
                      {GenerateLangText(
                        {
                          eng: "By using query tracking code you can view current status",
                          fr: "En utilisant le code de suivi des plaintes, vous pouvez voir l'état actuel",
                          kiny: "Koresha code y’Ikibazo/Icyifuzo watanze umenye aho kigeze gikorwaho",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="pt-3">
                      <Link
                        to={`/track-complaint`}
                        className="w-full cursor-pointer hover:bg-primary-700 hover:text-white hover:border-white rounded-full border border-primary-300 text-primary-800 font-normal px-4 pr-1 py-1 flex flex-row items-center justify-between gap-3 group"
                      >
                        <div>
                          {GenerateLangText(
                            {
                              eng: "Continue",
                              fr: "Continuer",
                              kiny: "Komeza",
                            },
                            this.props.system.language
                          )}
                        </div>
                        <div>
                          <IoIosArrowDroprightCircle className="text-3xl text-primary-300 group-hover:text-white" />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-12 lg:col-span-4 rounded-xl bg-white h-full p-4 md:p-6">
                    <div>
                      <BiSupport className="text-7xl text-green-500" />
                    </div>
                    <div className="text-xl font-bold mt-3">
                      {GenerateLangText(
                        {
                          eng: "How it Works?",
                          fr: "Comment ça fonctionne?",
                          kiny: "Uko bikorwa",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="mt-1 font-light text-gray-500 pt-5">
                      {/* {GenerateLangText(
                        {
                          eng: "Our customer support system provides you real time chatting room",
                          fr: "Notre système de support client vous offre une salle de chat en temps réel",
                          kiny: "Baza ikibazo cyangwa icyifuzo ufite duhite tugusubiza ako kanya",
                        },
                        this.props.system.language
                      )} */}
                      <div className="flex flex-row items-center gap-2 cursor-pointer group hover:text-yellow-700 text-base hover:underline mb-1">
                        <div>
                          <BsFileEarmarkPdf className="text-xl text-yellow-500 group-hover:text-yellow-700" />
                        </div>
                        <a
                          href={`${server}/${GenerateLangText(
                            {
                              eng: "User_guide_1English_Customers_of_Rwanda_FDA.pdf",
                              fr: "User_guide_1French_Customers_of_Rwanda_FDA.pdf",
                              kiny: "User_guide_1Kiny_Customers_of_Rwanda_FDA.pdf",
                            },
                            this.props.system.language
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                          title="Click to view documentation"
                        >
                          {GenerateLangText(
                            {
                              eng: "Click to download PDF document",
                              fr: "Télécharger le document PDF",
                              kiny: "Reba uko bikora hano",
                            },
                            this.props.system.language
                          )}
                        </a>
                      </div>
                      {/* <div className="flex flex-row items-center gap-2 cursor-pointer group hover:text-red-500 text-base hover:underline">
                        <div>
                          <RiYoutubeLine className="text-xl text-red-500 group-hover:text-red-500" />
                        </div>
                        <div>
                          {GenerateLangText(
                            {
                              eng: "Watch the Video",
                              fr: "Voir la vidéo",
                              kiny: "Reba Video",
                            },
                            this.props.system.language
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="pt-6 container mx-auto">
                  <div className="bg-white rounded-xl p-5 md:p-7 mb-5 flex flex-col md:flex-row md:items-center gap-5 animate__animated animate__fadeIn animate__slow">
                    <div>
                      <BsInfoCircle className="text-6xl text-accent-800 animate-pulse" />
                    </div>
                    <div>
                      <div className="text-xl font-bold">
                        {GenerateLangText(
                          {
                            eng: "What is Rwanda FDA Customer Support?",
                            fr: "Qu'est-ce que le support client de la FDA du Rwanda?",
                            kiny: "Rwanda FDA Customer Support ni iki?",
                          },
                          this.props.system.language
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {GenerateLangText(
                          {
                            eng: `The Rwanda FDA Customer Support System is an online platform designed to assist customers and citizens in submitting inquiries and requests related to products and services regulated by the Rwanda Food and Drugs Authority. The system provides a tracking code for each query, allowing customers to monitor the status of their requests. It also sends automated notifications via SMS and email to customers and relevant managers, ensuring effective communication and timely responses. Customers are also advised to send their feedback on the queries marked as completed.`,
                            fr: `Customer Support System de Rwanda FDA est une plate-forme en ligne conçue pour aider les clients et les citoyens à soumettre des demandes de renseignements et des demandes liées aux produits et services réglementés par l'Autorité rwandaise des produits alimentaires et pharmaceutiques (Rwanda FDA). Le système fournit un code de suivi pour chaque requête, permettant aux clients de suivre l'état de leurs demandes. Il envoie également des notifications automatisées par SMS et e-mail aux clients et aux responsables concernés, garantissant ainsi une communication efficace et des réponses rapides. Il est également conseillé aux clients d'envoyer leurs commentaires sur les requêtes marquées comme terminées.`,
                            kiny: "Customer Support System ya Rwanda FDA ni urubuga rwa interineti rwagenewe gufasha abakiriya n’abaturage mu gutanga ibibazo/Ibyifuzo no gusobanuza ku bijyanye n’ibicuruzwa na serivisi bigengwa na Rwanda FDA. Customer Support Sysytem itanga kode umukiriya akoresha akurikirana  uko ibibazo/Ibyifuzo bagaraje bigenda bikorwaho. Customer Support System yoherereza ubutumwa bugufi binyuze kuri SMS na imeri ku bakiriya n'abayobozi bireba igihe cyose hari ibyakozwe kuri bibazo/Ibyifuzo byagaragajwe. Abakiriya nabo basabwe kohereza ibitekerezo (Feedback) byabo kubibazo sistemu yagaragaje ko byarangiye.",
                          },
                          this.props.system.language
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-xl p-5">
                    <div className="font-bold text-xl mb-3">
                      {GenerateLangText(
                        {
                          eng: "Frequently Asked Questions",
                          fr: "Questions fréquemment posées",
                          kiny: "Ibibazo Bikunze Kubazwa",
                        },
                        this.props.system.language
                      )}
                    </div>
                    <div className="flex flex-col gap-3">
                      {this.props.system.FAQs === null ? (
                        <div>
                          <LoaderComponent />
                        </div>
                      ) : (
                        this.props.system.FAQs.map((item, i) => (
                          <FAQItem
                            key={i + 1}
                            data={item}
                            selectedLanguage={this.props.system.language}
                            openedFaq={this.state.openedFaq}
                            setOpenedFAQ={(
                              openedFaq: FAQuestionInterface | null
                            ) => this.setState({ openedFaq: openedFaq })}
                          />
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-12 pt-4">
                <Footer language={this.props.system.language} />
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): { auth: Auth; system: System } => {
  return { auth, system };
};

export const Homepage = connect(mapStateToProps, {
  FC_Login,
  FC_GetSystemInfo,
  FC_GetFrequentlyAskedQuestions,
})(_App);
