import React, { Fragment, Suspense } from "react";
import { connect } from "react-redux";
import {
  Auth,
  FC_CheckLoggedIn,
  FC_GetSystemInfo,
  FC_Logout,
  System,
  FC_SetError,
  FC_SetSuccess,
  FC_ChangeSystemLanguage,
  SocketComplaintData,
  FC_AddSocketComplaint,
  FC_GetSocketComplaint,
} from "./actions";
import { StoreState } from "./reducers";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LazyLoading from "./components/LazyLoading/LazyLoading";
import { Homepage } from "./containers/Homepage/Homepage";
import MainLoading from "./components/MainLoading/MainLoading";
import { NavBar } from "./components/NavBar/NavBar";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import AppLoading from "./components/AppLoading/AppLoading";
import Alert, { AlertType } from "./components/Alert/Alert";
import NetworkError from "./components/NetworkError/NetworkError";
import { SubmitComplaint } from "./containers/SubmitComplaint/SubmitComplaint";
import { TrackComplaint } from "./containers/TrackComplaint/TrackComplaint";
import { LanguageEnum } from "./components/SelectService/SelectService";

// props for the component
interface AppProps {
  auth: Auth;
  system: System;
  FC_CheckLoggedIn: (callBack: (status: boolean) => void) => void;
  FC_Logout: () => void;
  FC_GetSystemInfo: (callback: (loading: boolean) => void) => void;
  FC_SetError: (msg: string) => void;
  FC_SetSuccess: (msg: string) => void;
  FC_ChangeSystemLanguage: (language: LanguageEnum) => void;
  FC_AddSocketComplaint: (data: SocketComplaintData) => void;
  FC_GetSocketComplaint: () => void;
}

interface AppState {
  loading: boolean;
  openSideNav: boolean;
}

class _App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      loading: false,
      openSideNav: true,
    };
  }

  componentDidMount() {
    //* check if the user is logged in
    this.setState({ loading: true });
    this.props.FC_CheckLoggedIn((status: boolean) => {
      if (status === true) {
        this.setState({ loading: false });
      }
    });
  }

  render() {
    if (this.props.auth.loading === true) {
      return <MainLoading />;
    }

    if (this.state.loading === true) {
      return <AppLoading />;
    }
    return (
      <Fragment>
        <Router basename="/">
          <div className="h-screen">
            {this.props.system.success !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.SUCCESS}
                  title={"Done successfully!"}
                  description={this.props.system.success}
                  close={() => this.props.FC_SetSuccess("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {this.props.system.error !== "" && (
              <div className="fixed right-3 top-14 z-50 animate__animated animate__zoomInUp animate__fast pt-3">
                <Alert
                  alertType={AlertType.DANGER}
                  title={"Error occurred!"}
                  description={this.props.system.error}
                  close={() => this.props.FC_SetError("")}
                  className="shadow-xl"
                />
              </div>
            )}
            {/* Check connectivity */}
            {navigator.onLine === false && (
              <NetworkError lang={this.props.system.language} />
            )}
            {
              <NavBar
                auth={this.props.auth}
                FC_Logout={this.props.FC_Logout}
                sideNavbarStatus={this.state.openSideNav}
                setOpenVav={(status: boolean) =>
                  this.setState({ openSideNav: status })
                }
                SwitchEmployment={() => {}}
                language={this.props.system.language}
                onChangeLanguage={(language: LanguageEnum) => {
                  this.props.FC_ChangeSystemLanguage(language);
                }}
              />
            }
            <div
              className={`${
                this.props.auth.isAuthenticated === true
                  ? `bg-gray-200 h-full ${
                      this.state.openSideNav === true ? " w-full md:pl-64" : ""
                    } pt-14 overflow-y-auto`
                  : ""
              }`}
              style={{ zIndex: 9 }}
            >
              {this.props.auth.isAuthenticated === true &&
                this.state.openSideNav === true && (
                  <SideNavBar
                    auth={this.props.auth}
                    sideNavbarStatus={this.state.openSideNav}
                    setOpenVav={(status: boolean) =>
                      this.setState({ openSideNav: status })
                    }
                  />
                )}
              <div
                className={`${
                  this.props.auth.isAuthenticated === true
                    ? "p-1 md:p-2 h-full mt-5 md:mt-0 container mx-auto lg:px-2"
                    : ""
                }`}
              >
                <Switch>
                  <Route exact path="/" component={Homepage} />
                  <Route
                    exact
                    path="/submit-complaint"
                    component={SubmitComplaint}
                  />
                  <Route
                    exact
                    path="/submit-complaint/:communication_channel_id/:user_id/:communication_channel"
                    component={SubmitComplaint}
                  />
                  <Route
                    exact
                    path="/track-complaint"
                    component={TrackComplaint}
                  />
                  <Route
                    exact
                    path="/track-complaint/:complaint_tracking_code"
                    component={TrackComplaint}
                  />
                  <Suspense fallback={<LazyLoading />}></Suspense>
                </Switch>
              </div>
            </div>
            {/* Footer */}
            {/* {this.props.auth.isAuthenticated === false && <Footer />} */}
          </div>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return {
    auth,
    system,
  };
};

export const App = connect(mapStateToProps, {
  FC_CheckLoggedIn,
  FC_Logout,
  FC_GetSystemInfo,
  FC_SetError,
  FC_SetSuccess,
  FC_ChangeSystemLanguage,
  FC_AddSocketComplaint,
  FC_GetSocketComplaint,
})(_App);
