import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import {
  ComplaintStatus,
  FC_GetProductComplaintDetailsByTackingCode,
  FC_GetServiceComplaintDetailsByTackingCode,
  ProductComplaintDetailsInterface,
  ServicesStore,
  System,
} from "../../actions";
import { connect } from "react-redux";
import Container from "../../components/Container/Container";
import BackButton from "../../components/Fragments/BackButton";
import { Link, RouteComponentProps } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { NoResultFound } from "../../components/Fragments/NoResultFound";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { ServiceComplaintDetailsComponent } from "../../components/CpmplaintDetails/ServiceComplaintDetailsComponent";
import { LanguageEnum } from "../../components/SelectService/SelectService";
import { GenerateLangText } from "../../utils/functions";
import { ProductComplaintDetailsComponent } from "../../components/CpmplaintDetails/ProductComplaintDetailsComponent";

export interface ServiceComplaintDetails {
  service_complaint_id: string;
  customer_id: string;
  document_version_id: string;
  service_id: string;
  service_category_id: string;
  unit_id: string;
  expiration_date: string | null;
  service_render_date: string | null;
  rfd_offer_name: string | null;
  short_reason: string;
  description: string;
  support_doc_name: string | null;
  country_code: string | null;
  district_code: string | null;
  complaint_tracking_code: string;
  created_at: string;
  complaint_status: ComplaintStatus;
  progress_reason: string | null;
  progress_date: string | null;
  completed_reason: string | null;
  completed_date: string | null;
  rejected_reason: string | null;
  rejected_date: string | null;
  customer_feedback: string | null;
  customer_feedback_date: string | null;
  names: string | null;
  phone_number: string | null;
  email: string | null;
  gender: string | null;
  age: string | null;
  kiny_service: string | null;
  eng_service: string | null;
  fr_service: string | null;
  kiny_service_category: string;
  eng_service_category: string;
  fr_service_category: string;
  complaint_reason_en: string;
  complaint_reason_fr: string;
  complaint_reason_kiny: string;
}

interface TrackComplaintProps
  extends RouteComponentProps<{
    complaint_tracking_code: string | undefined;
  }> {
  system: System;
  services: ServicesStore;
  FC_GetServiceComplaintDetailsByTackingCode: (
    trackingCode: string,
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: ServiceComplaintDetails[];
      } | null
    ) => void
  ) => void;
  FC_GetProductComplaintDetailsByTackingCode: (
    trackingCode: string,
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
        data: ProductComplaintDetailsInterface[];
      } | null
    ) => void
  ) => void;
}
interface TrackComplaintState {
  loading: boolean;
  error: string;
  success: string;
  trackingCode: string;
  serviceComplaintDetails: ServiceComplaintDetails | null;
  productComplaintDetails: ProductComplaintDetailsInterface | null;
}

export class _TrackComplaint extends Component<
  TrackComplaintProps,
  TrackComplaintState
> {
  constructor(props: TrackComplaintProps) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: "",
      trackingCode: this.props.match.params.complaint_tracking_code ?? "",
      serviceComplaintDetails: null,
      productComplaintDetails: null,
    };
  }
  GetComplaintByTrackingCode = (
    trackingCode: string,
    callBack?: (loaded: boolean) => void
  ) => {
    this.setState({ loading: true });
    if (trackingCode[0] === "1") {
      this.props.FC_GetServiceComplaintDetailsByTackingCode(
        trackingCode,
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: ServiceComplaintDetails[];
          } | null
        ) => {
          this.setState({ loading: loading });
          if (
            res !== null &&
            res.type === "SUCCESS" &&
            res.data !== null &&
            res.data.length > 0
          ) {
            this.setState({
              serviceComplaintDetails: res.data[0],
              productComplaintDetails: null,
              error: "",
              success: "",
            });
            if (callBack !== undefined) {
              callBack(true);
            }
          }
          if (res?.type === "ERROR") {
            this.setState({
              success: "",
              error: res.msg,
              serviceComplaintDetails: null,
              productComplaintDetails: null,
            });
          }
          if (res?.type === "SUCCESS" && res.data.length === 0) {
            this.setState({
              success: "",
              error: GenerateLangText(
                {
                  eng: "Query you are searching for is not available",
                  fr: "La requête que vous recherchez n'est pas disponible",
                  kiny: "Ikibazo/Icyifuzo urimo gushakisha ntikiboneka",
                },
                this.props.system.language
              ),
              serviceComplaintDetails: null,
              productComplaintDetails: null,
            });
          }
        }
      );
    } else {
      this.props.FC_GetProductComplaintDetailsByTackingCode(
        trackingCode,
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: ProductComplaintDetailsInterface[];
          } | null
        ) => {
          this.setState({ loading: loading });
          if (
            res !== null &&
            res.type === "SUCCESS" &&
            res.data !== null &&
            res.data.length > 0
          ) {
            this.setState({
              productComplaintDetails: res.data[0],
              serviceComplaintDetails: null,
              error: "",
              success: "",
            });
            if (callBack !== undefined) {
              callBack(true);
            }
          }
          if (res?.type === "ERROR") {
            this.setState({
              success: "",
              error: res.msg,
              productComplaintDetails: null,
              serviceComplaintDetails: null,
            });
          }
          if (res?.type === "SUCCESS" && res.data.length === 0) {
            this.setState({
              success: "",
              error: GenerateLangText(
                {
                  eng: "Query you are searching for is not available",
                  fr: "La requête que vous recherchez n'est pas disponible",
                  kiny: "Ikibazo/Icyifuzo urimo gushakisha ntikiboneka",
                },
                this.props.system.language
              ),
              productComplaintDetails: null,
              serviceComplaintDetails: null,
            });
          }
        }
      );
    }
  };
  onSearchComplaint = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.trackingCode === "") {
      return this.setState({
        error: GenerateLangText(
          {
            eng: "Please fill query tracking code",
            fr: "Veuillez remplir le code de suivi de la requête",
            kiny: "Uzuza kode yo gukurikirana Ikibazo/Icyifuzo cyawe",
          },
          this.props.system.language
        ),
        success: "",
      });
    }
    this.setState({ error: "", success: "" });
    this.GetComplaintByTrackingCode(this.state.trackingCode);
  };
  componentDidMount(): void {
    if (this.state.trackingCode !== "") {
      this.GetComplaintByTrackingCode(this.state.trackingCode);
    }
  }
  render() {
    return (
      <Fragment>
        <div className="bg-gray-200 min-h-screen">
          <Container className=" pb-24">
            <div
              className={`bg-white rounded-xl mt-6 animate__animated animate__fadeInLeft animate__faster`}
            >
              <div className={`p-2 border-b`}>
                <div className="flex flex-row items-center gap-4 w-full">
                  <Link to={"/"}>
                    <BackButton
                      title={GenerateLangText(
                        {
                          eng: `Back Home`,
                          fr: "Retour",
                          kiny: "Subira inyuma",
                        },
                        this.props.system.language
                      )}
                      className="border rounded-lg hover:bg-primary-50 hover:text-primary-800 hover:border-primary-700"
                    />
                  </Link>
                  <div className="text-xl font-bold uppercase">
                    {GenerateLangText(
                      {
                        eng: `Query Tracking`,
                        fr: "Suivi des réclamations",
                        kiny: "Gukurikirana Ikibazo/Icyifuzo cyawe",
                      },
                      this.props.system.language
                    )}
                  </div>
                </div>
              </div>
              <div className="p-3">
                <form
                  onSubmit={this.onSearchComplaint}
                  className="flex flex-row items-center justify-between w-full gap-2"
                >
                  <div className="w-full">
                    <input
                      type="text"
                      className={`px-3 py-2 rounded-md border-2 ${
                        this.state.loading === true
                          ? "border-gray-300"
                          : "border-primary-700"
                      } w-full flex items-center h-10 text-primary-800 font-bold focus:outline-none`}
                      placeholder={GenerateLangText(
                        {
                          eng: `Tracking Code`,
                          fr: "Code de suivi",
                          kiny: "Kode yo gukurikirana ikibazo cyawe",
                        },
                        this.props.system.language
                      )}
                      autoFocus={true}
                      value={this.state.trackingCode}
                      onChange={(e) =>
                        this.setState({
                          trackingCode: e.target.value,
                          error: "",
                        })
                      }
                      disabled={this.state.loading}
                    />
                  </div>
                  <button
                    type="submit"
                    className={`flex flex-row items-center justify-center gap-2 ${
                      this.state.loading === true
                        ? "bg-gray-100 cursor-not-allowed"
                        : "bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
                    } px-3 py-2 pl-2 rounded-md`}
                    disabled={this.state.loading}
                  >
                    <div>
                      {this.state.loading === true ? (
                        <AiOutlineLoading3Quarters className="text-2xl animate-spin text-yellow-500" />
                      ) : (
                        <FiSearch className="text-2xl" />
                      )}
                    </div>
                    {this.state.loading === true ? (
                      <span className="animate__animated animate__fadeIn animate__infinite">
                        {GenerateLangText(
                          {
                            eng: `Searching...`,
                            fr: "Recherche...",
                            kiny: "Gushakisha...",
                          },
                          this.props.system.language
                        )}
                      </span>
                    ) : (
                      <span>
                        {GenerateLangText(
                          {
                            eng: `Search`,
                            fr: "Recherche",
                            kiny: "Gushakisha",
                          },
                          this.props.system.language
                        )}
                      </span>
                    )}
                  </button>
                </form>
              </div>
              {this.state.error !== "" && (
                <div className="p-3">
                  <Alert
                    alertType={AlertType.DANGER}
                    title={this.state.error}
                    close={() => this.setState({ error: "", success: "" })}
                  />
                </div>
              )}
              {this.state.success !== "" && (
                <div className="p-3">
                  <Alert
                    alertType={AlertType.SUCCESS}
                    title={this.state.success}
                    close={() => this.setState({ error: "", success: "" })}
                  />
                </div>
              )}
              <div className="p-3 pt-0">
                {this.state.serviceComplaintDetails === null &&
                  this.state.productComplaintDetails === null && (
                    <NoResultFound
                      title={GenerateLangText(
                        {
                          eng: `Search By Tracking Code`,
                          fr: "Rechercher par code de suivi",
                          kiny: "Shakisha Ukurikirana Kode",
                        },
                        this.props.system.language
                      )}
                      description={GenerateLangText(
                        {
                          eng: `Please type query tracking code to view query details`,
                          fr: "Veuillez saisir le code de suivi des réclamations pour afficher les détails de la requête",
                          kiny: "Nyamuneka andika kode ikurikirana Ikibazo/Icyifuzo kugirango urebe ibisobanuro",
                        },
                        this.props.system.language
                      )}
                    />
                  )}
                {(this.state.serviceComplaintDetails !== null ||
                  this.state.productComplaintDetails !== null) && (
                  <div className="animate__animated animate__fadeIn">
                    {this.state.trackingCode[0] === "1" &&
                    this.state.serviceComplaintDetails !== null ? (
                      <ServiceComplaintDetailsComponent
                        complaintDetails={this.state.serviceComplaintDetails}
                        selectedLanguage={this.props.system.language}
                        onBack={() => {}}
                        onSubmitFeedback={(
                          data: {
                            customer_feedback: string;
                            service_complaint_id: string;
                          },
                          callBack: (completedChanges: boolean) => void
                        ) => {
                          if (this.state.serviceComplaintDetails !== null) {
                            this.setState({ loading: true });
                            this.GetComplaintByTrackingCode(
                              this.state.serviceComplaintDetails
                                .complaint_tracking_code,
                              (loaded: boolean) => {
                                if (loaded === true) {
                                  callBack(true);
                                }
                              }
                            );
                          }
                        }}
                      />
                    ) : (
                      this.state.productComplaintDetails !== null && (
                        <ProductComplaintDetailsComponent
                          complaintDetails={this.state.productComplaintDetails}
                          selectedLanguage={LanguageEnum.ENG}
                          onBack={() => {}}
                          onSubmitFeedback={(
                            data: {
                              customer_feedback: string;
                              product_complaint_id: string;
                            },
                            callBack: (completedChanges: boolean) => void
                          ) => {
                            if (this.state.productComplaintDetails !== null) {
                              this.setState({ loading: true });
                              this.GetComplaintByTrackingCode(
                                this.state.productComplaintDetails
                                  .complaint_tracking_code,
                                (loaded: boolean) => {
                                  if (loaded === true) {
                                    callBack(true);
                                  }
                                }
                              );
                            }
                          }}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  system,
  services,
}: StoreState): { system: System; services: ServicesStore } => {
  return { system, services };
};

export const TrackComplaint = connect(mapStateToProps, {
  FC_GetServiceComplaintDetailsByTackingCode,
  FC_GetProductComplaintDetailsByTackingCode,
})(_TrackComplaint);
