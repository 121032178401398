import React, { Component } from "react";
import { BsArrowRightCircle } from "react-icons/bs";
import { MdOutlineSell } from "react-icons/md";
import { RiCustomerServiceFill } from "react-icons/ri";
import { GenerateLangText } from "../../utils/functions";
import { LanguageEnum } from "../../components/SelectService/SelectService";

export enum COMPLAINT_TYPE {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}

interface SelectComplaintTypeProps {
  selectedComplaintType: COMPLAINT_TYPE | null;
  onSelectComplaintType: (complaintType: COMPLAINT_TYPE) => void;
  language: LanguageEnum;
}
interface SelectComplaintTypeState {}

export class SelectComplaintType extends Component<
  SelectComplaintTypeProps,
  SelectComplaintTypeState
> {
  constructor(props: SelectComplaintTypeProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-3 pt-8 md:pt-10 pb-8 md:pb-3 lg:pb-10 flex flex-col items-center justify-center gap-4 w-full">
        <div className="text-center text-xl mb-3">
          {GenerateLangText(
            {
              eng: "What type of query are you going to submit?",
              fr: "Quel type de plainte allez-vous déposer?",
              kiny: "Ni ubuhe bwoko bw'Ikibazo/Icyifuzo ugiye gutanga?",
            },
            this.props.language
          )}
        </div>
        <div className="grid grid-cols-12 gap-3 lg:gap-6 w-full">
          <div className="col-span-12 lg:col-span-2"></div>
          <div
            onClick={() =>
              this.props.onSelectComplaintType(COMPLAINT_TYPE.PRODUCT)
            }
            title={GenerateLangText(
              {
                eng: "Click to submit your query",
                fr: "Cliquez pour soumettre votre plainte",
                kiny: "Kanda kugirango utange Ikibazo/Icyifuzo cyawe",
              },
              this.props.language
            )}
            className="col-span-12 md:col-span-6 lg:col-span-4 rounded-xl border hover:border-primary-700 p-4 group border-primary-300 bg-primary-50 cursor-pointer"
          >
            <div>
              <div className="flex flex-row justify-between gap-3">
                <div>
                  <MdOutlineSell className="text-6xl text-primary-300 group-hover:text-primary-700 animate__animated animate__bounceIn animate__slower" />
                </div>
              </div>
              <div className="font-bold text-xl my-2">
                {GenerateLangText(
                  {
                    eng: "On Product",
                    fr: "Sur le produit",
                    kiny: "Ku bicuruzwa",
                  },
                  this.props.language
                )}
              </div>
              <div className="font-light text-sm">
                {GenerateLangText(
                  {
                    eng: "Click here when you want to submit query on a specific product you have problem with",
                    fr: "Cliquez ici lorsque vous souhaitez soumettre une requête sur un produit spécifique avec lequel vous rencontrez un problème",
                    kiny: "Kanda hano mugihe ushaka gutanga Ikibazo/Icyifuzo kubicuruzwa runaka",
                  },
                  this.props.language
                )}
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                <div>
                  <BsArrowRightCircle className="text-3xl text-primary-700" />
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              this.props.onSelectComplaintType(COMPLAINT_TYPE.SERVICE);
            }}
            title={GenerateLangText(
              {
                eng: "Click to submit your query",
                fr: "Cliquez pour soumettre votre plainte",
                kiny: "Kanda kugirango utange Ikibazo/Icyifuzo cyawe",
              },
              this.props.language
            )}
            className="col-span-12 md:col-span-6 lg:col-span-4 rounded-xl border hover:border-yellow-500 p-4 group border-yellow-300 bg-yellow-50 cursor-pointer"
          >
            <div>
              <div className="flex flex-row justify-between gap-3">
                <div>
                  <RiCustomerServiceFill className="text-6xl text-yellow-300 group-hover:text-yellow-500 animate__animated animate__bounceIn animate__slower" />
                </div>
              </div>
              <div className="font-bold text-xl my-2">
                {GenerateLangText(
                  {
                    eng: "On Service",
                    fr: "Sur le service",
                    kiny: "Kuri serivisi",
                  },
                  this.props.language
                )}
              </div>
              <div className="font-light text-sm">
                {GenerateLangText(
                  {
                    eng: "Click here when you want to submit query on a specific service you were received in Rwanda FDA",
                    fr: "Cliquez ici lorsque vous souhaitez soumettre une requête sur un service spécifique que vous avez reçu au Rwanda FDA",
                    kiny: "Kanda hano mugihe ushaka gutanga Ikibazo/Icyifuzo kuri serivisi runaka wakiriwe mu Rwanda FDA",
                  },
                  this.props.language
                )}
              </div>
              <div className="flex flex-row items-center justify-end gap-2">
                <div>
                  <BsArrowRightCircle className="text-3xl text-yellow-500" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
