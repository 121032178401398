import React, { Component } from "react";
import { StoreState } from "../../reducers";
import {
  ComplaintStatus,
  FC_AddSocketComplaint,
  FC_GetLocationsDetails,
  FC_GetServiceComplaintTypes,
  FC_SubmitProductComplaint,
  LocationAPI,
  ServiceComplaintType,
  ServiceItem,
  ServicesStore,
  SocketComplaintData,
  SubmitProductFormData,
  System,
} from "../../actions";
import { connect } from "react-redux";
import Input from "../../components/Fragments/Input";
import Textarea from "../../components/Fragments/Textarea";
import {
  DragDropFile,
  RejectedFileInterface,
} from "../../components/FileUpload/FileUpload";
import SelectCustom from "../../components/SelectCustom/SelectCustom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Alert, { AlertType } from "../../components/Alert/Alert";
import { HiOutlineDocumentText } from "react-icons/hi";
import { LanguageEnum } from "../../components/SelectService/SelectService";
import { COMPLAINT_TYPE } from "./SelectComplaintType";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { ImCheckboxUnchecked } from "react-icons/im";
import { GenerateLangText } from "../../utils/functions";

interface ProductComplaintFormProps {
  services: ServicesStore;
  selectedService: ServiceItem;
  system: System;
  FC_SubmitProductComplaint: (
    data: SubmitProductFormData,
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        data: {
          customer_id: string;
          message: string;
          product_complaint_id: string;
          complaint_tracking_code: string;
        } | null;
        msg: string;
      } | null
    ) => void
  ) => void;
  FC_GetServiceComplaintTypes: (callback: (loading: boolean) => void) => void;
  onSubmitted: (data: {
    customer_id: string;
    message: string;
    product_complaint_id: string;
    complaint_tracking_code: string;
  }) => void;
  language: LanguageEnum;
  submitted_by: string | null;
  communication_channel: {
    communication_channel_id: string;
    communication_channel: string;
  };
  previewDoc: File | null;
  setPreviewDoc: (file: File) => void;
  onGoBack: () => void;
  FC_AddSocketComplaint: (data: SocketComplaintData) => void;
}
interface ProductComplaintFormState {
  success: string;
  error: {
    target:
      | "main"
      | "description"
      | "selectedReason"
      | "product_name"
      | "product_image_name";
    msg: string;
  } | null;
  loading: boolean;
  showSelectCountry: boolean;
  showSelectDistrict: boolean;
  selectedCountry: { country_code: string; country_name: string } | null;
  selectedDistrict: { district_code: string; district_name: string } | null;
  locations_loading: boolean;
  locations: LocationAPI | null;
  short_reason: string;
  product_name: string;
  description: string;
  product_image_name: { file: File; preview: string } | null;
  selectedReason: ServiceComplaintType | null;
  openSelectReason: boolean;
  complaints_loading: boolean;
  acceptedTerms: boolean;
}

class _ProductComplaintForm extends Component<
  ProductComplaintFormProps,
  ProductComplaintFormState
> {
  constructor(props: ProductComplaintFormProps) {
    super(props);
    this.state = {
      success: "",
      error: null,
      loading: false,
      showSelectCountry: false,
      showSelectDistrict: false,
      selectedCountry: null,
      selectedDistrict: null,
      locations_loading: false,
      locations: null,
      product_image_name: null,
      description: "",
      product_name: "",
      short_reason: "",
      selectedReason: null,
      openSelectReason: false,
      complaints_loading: false,
      acceptedTerms: false,
    };
  }
  GetLocations = () => {
    this.setState({ locations_loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        if (res?.type === "Success") {
          this.setState({
            locations_loading: loading,
            locations: res.data,
            error: null,
          });
        }
        if (res?.type === "Error") {
          this.setState({
            locations_loading: false,
            error: { target: "main", msg: res.msg },
          });
        }
      }
    );
  };
  SubmitServiceComplaint = () => {
    if (this.state.product_name === "") {
      return this.setState({
        error: {
          target: "product_name",
          msg: GenerateLangText(
            {
              eng: "Please add product name",
              fr: "Veuillez ajouter le nom du produit",
              kiny: "Nyamuneka shyiraho izina ryibicuruzwa",
            },
            this.props.language
          ),
        },
      });
    }
    if (this.state.selectedReason === null) {
      return this.setState({
        error: {
          target: "selectedReason",
          msg: GenerateLangText(
            {
              eng: "Please choose complaint type",
              fr: "Veuillez choisir un type de plainte",
              kiny: "Nyamuneka hitamo ubwoko bw'ikirego",
            },
            this.props.language
          ),
        },
      });
    }
    if (this.state.description === "") {
      return this.setState({
        error: {
          target: "description",
          msg: GenerateLangText(
            {
              eng: "Please describe your complaint",
              fr: "Veuillez décrire votre plainte",
              kiny: "Nyamuneka sobanura ikibazo cyawe",
            },
            this.props.language
          ),
        },
      });
    }
    if (this.state.product_image_name === null) {
      return this.setState({
        error: {
          target: "product_image_name",
          msg: GenerateLangText(
            {
              eng: "Please upload product image/photo",
              fr: "Veuillez télécharger l'image/la photo du produit",
              kiny: "Nyamuneka ohereza ifoto y' igicuruzwa",
            },
            this.props.language
          ),
        },
      });
    }
    this.props.FC_SubmitProductComplaint(
      {
        country_code:
          this.state.selectedCountry === null
            ? null
            : this.state.selectedCountry.country_code,
        description: this.state.description,
        district_code:
          this.state.selectedDistrict === null
            ? null
            : this.state.selectedDistrict.district_code,
        service_id: this.props.selectedService.service_id,
        short_reason: parseInt(this.state.selectedReason.complaint_reason_id),
        product_image_name:
          this.state.product_image_name === null
            ? null
            : this.state.product_image_name.file,
        service_category_id: this.props.selectedService.service_category_id,
        unit_id: this.props.selectedService.unit_id,
        product_name: this.state.product_name,
        complaint_lang: this.props.language,
        communication_channel_id:
          this.props.communication_channel.communication_channel_id,
        submitted_by: this.props.submitted_by,
      },
      (
        loading: boolean,
        res: {
          type: "SUCCESS" | "ERROR";
          data: {
            customer_id: string;
            message: string;
            product_complaint_id: string;
            complaint_tracking_code: string;
          } | null;
          msg: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.state.selectedReason !== null &&
            this.props.FC_AddSocketComplaint({
              complaint_status: ComplaintStatus.PENDING,
              reason_id: this.state.selectedReason.complaint_reason_id,
              unit_id: this.props.selectedService.unit_id,
              complaint_reason_en:
                this.state.selectedReason.complaint_reason_en,
            });
          this.setState({ error: null, success: res.msg, loading: false });
          setTimeout(() => {
            res.data !== null && this.props.onSubmitted(res.data);
          }, 1000);
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: { target: "main", msg: res.msg },
            loading: false,
          });
        }
      }
    );
  };
  componentDidMount(): void {
    if (this.state.locations === null) {
      this.GetLocations();
    }
    if (this.props.services.serviceComplaintTypes === null) {
      this.props.FC_GetServiceComplaintTypes((loading: boolean) =>
        this.setState({ complaints_loading: loading })
      );
    }
  }
  render() {
    return (
      <div className="p-3">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-6">
            <Input
              title={GenerateLangText(
                {
                  eng: "Name of product",
                  fr: "Nom du produit",
                  kiny: "Izina ry'igicuruzwa",
                },
                this.props.language
              )}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.setState({ product_name: e.target.value, error: null })
              }
              disabled={false}
              value={this.state.product_name}
              error={
                this.state.error?.target === "product_name"
                  ? this.state.error.msg
                  : ""
              }
              type={"text"}
              className="bg-gray-100 font-bold"
              requiredStar={true}
            />
          </div>
          <div className="col-span-12 md:col-span-6">
            <div className="">
              <div>
                <div className="text-sm">
                  {GenerateLangText(
                    {
                      eng: "Selected product",
                      fr: "Produit sélectionné",
                      kiny: "Ibicuruzwa byatoranijwe",
                    },
                    this.props.language
                  )}
                </div>
                <div className="font-bold px-3 py-2 rounded-md border w-full text-sm mt-2 bg-gray-100">
                  {this.props.language === LanguageEnum.ENG
                    ? this.props.selectedService.eng_service
                    : this.props.language === LanguageEnum.FR
                    ? this.props.selectedService.fr_service
                    : this.props.selectedService.kiny_service}
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <div className="relative z-50">
              <div className="flex flex-row items-center gap-1">
                <div className="text-sm pb-2">
                  {GenerateLangText(
                    {
                      eng: "Choose query reason",
                      fr: "Choisissez la raison de la requête",
                      kiny: "Hitamo impamvu ushaka kubaza ikibazo | gutanga icyifuzo",
                    },
                    this.props.language
                  )}
                </div>
                <div className="flex flex-row items-center">
                  <div className="text-sm text-red-500 flex flex-row items-start">
                    (<span className="text-xl font-bold">*</span>)
                  </div>
                </div>
              </div>
              {this.state.openSelectReason === false &&
                this.state.complaints_loading === false && (
                  <div
                    onClick={() =>
                      this.setState({ openSelectReason: true, error: null })
                    }
                    className={`flex flex-row items-center justify-between px-3 py-2 rounded-lg bg-white border-2 ${
                      this.state.error?.target === "selectedReason"
                        ? "border-red-500 animate__animated animate__shakeX"
                        : "border-primary-700"
                    } w-full cursor-pointer`}
                  >
                    {this.state.selectedReason === null ? (
                      <span className="text-gray-400">
                        {GenerateLangText(
                          {
                            eng: "Click to choose query reason",
                            fr: "Choisissez la raison de la requête",
                            kiny: "Kanda kugirango uhitemo impmavu",
                          },
                          this.props.language
                        )}
                      </span>
                    ) : (
                      <span className="font-bold">
                        {GenerateLangText(
                          {
                            eng: this.state.selectedReason.complaint_reason_en,
                            fr: this.state.selectedReason.complaint_reason_fr,
                            kiny: this.state.selectedReason
                              .complaint_reason_kiny,
                          },
                          this.props.language
                        )}
                      </span>
                    )}
                    <div>
                      <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                    </div>
                  </div>
                )}
              {this.state.error?.target === "selectedReason" && (
                <div className="py-2">
                  <Alert
                    alertType={AlertType.DANGER}
                    title={this.state.error.msg}
                    close={() => this.setState({ error: null })}
                  />
                </div>
              )}
              {this.state.complaints_loading === true ? (
                <div className="flex flex-row items-center gap-3 w-full my-2">
                  <div>
                    <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                  </div>
                  <div className="animate__animated animate__fadeIn animate__infinite text-yellow-500">
                    {GenerateLangText(
                      {
                        eng: "Loading Complaints",
                        fr: "Chargement des plaintes",
                        kiny: "Tegereza gato",
                      },
                      this.props.language
                    )}
                  </div>
                </div>
              ) : (
                this.state.openSelectReason === true && (
                  <div className="absolute left-0 right-0">
                    <SelectCustom
                      loading={
                        this.state.loading === true ??
                        this.state.complaints_loading
                      }
                      id={"complaint_reason_id"}
                      title={GenerateLangText(
                        {
                          eng: "complaint_reason_en",
                          fr: "complaint_reason_fr",
                          kiny: "complaint_reason_kiny",
                        },
                        this.props.language
                      )}
                      close={() => this.setState({ openSelectReason: false })}
                      data={
                        this.props.services.serviceComplaintTypes === null
                          ? []
                          : this.props.services.serviceComplaintTypes.filter(
                              (itm) => itm.category === COMPLAINT_TYPE.PRODUCT
                            )
                      }
                      click={(data: ServiceComplaintType) => {
                        this.setState({
                          openSelectReason: false,
                          selectedReason: {
                            category: COMPLAINT_TYPE.SERVICE,
                            complaint_reason_en: data.complaint_reason_en,
                            complaint_reason_fr: data.complaint_reason_fr,
                            complaint_reason_kiny: data.complaint_reason_kiny,
                            complaint_reason_id: data.complaint_reason_id,
                          },
                          error: null,
                          success: "",
                        });
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>

          <div className="col-span-12 md:col-span-6">
            <Textarea
              title={GenerateLangText(
                {
                  eng: "Query details",
                  fr: "Détails de la requête",
                  kiny: "Vuga Ikibazo/Icyifuzo mu buryo burambuye",
                },
                this.props.language
              )}
              onChange={(e) =>
                this.setState({
                  description: e.target.value,
                  error: null,
                })
              }
              disabled={false}
              value={this.state.description}
              error={
                this.state.error?.target === "description"
                  ? this.state.error.msg
                  : ""
              }
              className="h-24 bg-gray-100 font-bold"
              requiredStar={true}
            />
          </div>
          <div className="col-span-12 md:col-span-6 pt-2">
            <div className="flex flex-row items-center gap-1 -mb-2">
              <div className="text-sm pb-2">
                {GenerateLangText(
                  {
                    eng: "Product Photo (Only Image) Max size: 1MB",
                    fr: "Photo du produit (image uniquement) Taille maximale:1Mo",
                    kiny: "Ifoto y'igicuruzwa (Ishusho Yonyine) Ingano nini: 1MB",
                  },
                  this.props.language
                )}
              </div>
              <div className="flex flex-row items-center">
                <div className="text-sm text-red-500 flex flex-row items-start">
                  (<span className="text-xl font-bold">*</span>)
                </div>
              </div>
            </div>
            {this.state.product_image_name === null ? (
              <div>
                <DragDropFile
                  className={
                    "p-4 py-5 rounded-md border-2 border-dashed text-center cursor-pointer bg-gray-100 border-gray-400 hover:bg-primary-50 hover:text-primary-900 hover:border-primary-700 animate__animated animate__fadeIn animate__slow"
                  }
                  allowedFileTypes={{ "image/*": [] }}
                  acceptedFiles={
                    this.state.product_image_name === null
                      ? []
                      : [this.state.product_image_name]
                  }
                  rejectedFiles={[]}
                  setAcceptedFiles={(
                    acceptedFiles: { file: File; preview: string }[]
                  ) => {
                    if (acceptedFiles.length > 0) {
                      this.setState({ product_image_name: acceptedFiles[0] });
                    }
                  }}
                  setRejectedFiles={(files: RejectedFileInterface[]) => {
                    if (files.length > 0) {
                      this.setState({
                        error: {
                          target: "main",
                          msg: files[0].errors[0].message,
                        },
                      });
                    }
                  }}
                  disabled={this.state.loading}
                  multiple={false}
                  language={this.props.language}
                />
                {this.state.error?.target === "product_image_name" && (
                  <Alert
                    alertType={AlertType.DANGER}
                    title={this.state.error.msg}
                    close={() => this.setState({ error: null, success: "" })}
                  />
                )}
              </div>
            ) : (
              <div className="flex flex-row justify-between gap-2 rounded-md bg-primary-50 my-2 px-3 py-3">
                <div className="flex flex-row items-center gap-2">
                  <div>
                    <HiOutlineDocumentText className="text-primary-700 text-5xl" />
                  </div>
                  <div>
                    <div className="font-bold">
                      {GenerateLangText(
                        {
                          eng: "Supporting Document",
                          fr: "Documents justificatifs",
                          kiny: "Igihamya",
                        },
                        this.props.language
                      )}
                    </div>
                    <div className="text-sm">
                      {this.state.product_image_name.file.name}
                    </div>
                    <div
                      onClick={() =>
                        this.state.product_image_name !== null &&
                        this.props.setPreviewDoc(
                          this.state.product_image_name.file
                        )
                      }
                      className="cursor-pointer underline text-primary-700 text-sm hover:text-green-600 font-bold"
                    >
                      {GenerateLangText(
                        {
                          eng: "Click to preview document",
                          fr: "Cliquez pour prévisualiser le document",
                          kiny: "Kanda kugirango urebe igihamya",
                        },
                        this.props.language
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => this.setState({ product_image_name: null })}
                    className="px-3 py-2 rounded-md bg-red-50 hover:bg-red-600 hover:text-white cursor-pointer text-sm font-bold w-max border border-red-400 hover:border-white"
                  >
                    {GenerateLangText(
                      {
                        eng: "Remove",
                        fr: "Retirer",
                        kiny: "Gusiba",
                      },
                      this.props.language
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-span-12">
            <div className="bg-gray-100 rounded-md">
              <div className="font-bold px-3 py-2 border-b mb-2">
                {GenerateLangText(
                  {
                    eng: "Location of Product",
                    fr: "Emplacement du produit",
                    kiny: "Aho igicuruzwa kibarizwa",
                  },
                  this.props.language
                )}
              </div>

              <div className="grid grid-cols-12 gap-4 pb-3 px-3">
                <div
                  className={`col-span-12 ${
                    this.state.selectedCountry !== null &&
                    this.state.selectedCountry.country_code === "RWA"
                      ? "md:col-span-6"
                      : ""
                  }`}
                >
                  <div className="relative z-10">
                    <div>
                      {GenerateLangText(
                        {
                          eng: "Choose Country",
                          fr: "Choisissez le pays",
                          kiny: "Hitamo Igihugu",
                        },
                        this.props.language
                      )}
                    </div>
                    {this.state.showSelectCountry === false && (
                      <div
                        onClick={() =>
                          this.setState({ showSelectCountry: true })
                        }
                        className="flex flex-row items-center justify-between px-3 py-2 rounded-md bg-white border border-gray-300 w-full cursor-pointer"
                      >
                        {this.state.selectedCountry === null ? (
                          <span className="text-gray-400">
                            {GenerateLangText(
                              {
                                eng: "Click to select country",
                                fr: "Cliquez pour sélectionner le pays",
                                kiny: "Kanda kugirango uhitemo igihugu",
                              },
                              this.props.language
                            )}
                          </span>
                        ) : (
                          this.state.selectedCountry.country_name
                        )}
                        <div>
                          <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                        </div>
                      </div>
                    )}
                    {this.state.locations_loading === true ? (
                      <div className="flex flex-row items-center gap-3 w-full">
                        <div>
                          <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                        </div>
                        <div className="animate__animated animate__fadeIn animate__infinite text-yellow-500">
                          {GenerateLangText(
                            {
                              eng: "Loading Locations",
                              fr: "Emplacements de chargement",
                              kiny: "Tegereza gato",
                            },
                            this.props.language
                          )}
                        </div>
                      </div>
                    ) : (
                      this.state.showSelectCountry === true && (
                        <div className="absolute left-0 right-0">
                          <SelectCustom
                            loading={
                              this.state.loading === true ??
                              this.state.locations_loading
                            }
                            id={"country_code"}
                            title={"country_name"}
                            close={() =>
                              this.setState({ showSelectCountry: false })
                            }
                            data={
                              this.state.locations === null
                                ? []
                                : this.state.locations.countries
                            }
                            click={(data: {
                              country_code: string;
                              country_name: string;
                            }) => {
                              this.setState({
                                selectedCountry: data,
                                showSelectCountry: false,
                                selectedDistrict: null,
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* Select District */}
                {this.state.selectedCountry !== null &&
                  this.state.selectedCountry.country_code === "RWA" && (
                    <div className={`col-span-12 md:col-span-6`}>
                      <div className="relative">
                        <div>
                          {GenerateLangText(
                            {
                              eng: "Choose District",
                              fr: "Sélectionnez le quartier",
                              kiny: "Hitamo Akarere",
                            },
                            this.props.language
                          )}
                        </div>
                        {this.state.showSelectDistrict === false && (
                          <div
                            onClick={() =>
                              this.setState({ showSelectDistrict: true })
                            }
                            className="flex flex-row items-center justify-between px-3 py-2 rounded-md bg-white border border-gray-300 w-full cursor-pointer"
                          >
                            {this.state.selectedDistrict === null ? (
                              <span className="text-gray-400">
                                {GenerateLangText(
                                  {
                                    eng: "Click to select district",
                                    fr: "Cliquez pour sélectionner le quartier",
                                    kiny: "Kanda kugirango uhitemo akarere",
                                  },
                                  this.props.language
                                )}
                              </span>
                            ) : (
                              this.state.selectedDistrict.district_name
                            )}
                            <div>
                              <MdOutlineKeyboardArrowDown className="text-2xl text-gray-400" />
                            </div>
                          </div>
                        )}
                        {this.state.locations_loading === true ? (
                          <div className="flex flex-row items-center gap-3 w-full">
                            <div>
                              <AiOutlineLoading3Quarters className="animate-spin text-3xl text-yellow-300" />
                            </div>
                            <div className="animate__animated animate__fadeIn animate__infinite text-yellow-500">
                              {GenerateLangText(
                                {
                                  eng: "Loading Locations",
                                  fr: "Emplacements de chargement",
                                  kiny: "Tegereza gato",
                                },
                                this.props.language
                              )}
                            </div>
                          </div>
                        ) : (
                          this.state.showSelectDistrict === true && (
                            <div className="absolute left-0 right-0">
                              <SelectCustom
                                loading={
                                  this.state.loading === true ??
                                  this.state.locations_loading
                                }
                                id={"district_code"}
                                title={"district_name"}
                                close={() =>
                                  this.setState({ showSelectDistrict: false })
                                }
                                data={
                                  this.state.locations === null
                                    ? []
                                    : this.state.locations.districts
                                }
                                click={(data: {
                                  district_code: string;
                                  district_name: string;
                                }) => {
                                  this.setState({
                                    selectedDistrict: data,
                                    showSelectDistrict: false,
                                  });
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  )}
                <div className="col-span-12 pt-3">
                  <div
                    className={`bg-white border ${
                      this.state.acceptedTerms === true
                        ? "border-green-500"
                        : "border-yellow-400"
                    } px-3 pl-2 py-2 flex flex-row items-center gap-2 rounded-md`}
                  >
                    <div
                      onClick={() =>
                        this.setState({
                          acceptedTerms: !this.state.acceptedTerms,
                        })
                      }
                      className="cursor-pointer group"
                    >
                      {this.state.acceptedTerms === true ? (
                        <BsFillCheckSquareFill className="text-3xl text-green-500 animate__animated animate__bounceIn" />
                      ) : (
                        <ImCheckboxUnchecked className="text-3xl text-red-500 group-hover:text-primary-700 animate__animated animate__fadeIn" />
                      )}
                    </div>
                    <div className="text-base">
                      {GenerateLangText(
                        {
                          eng: "I hereby acknowledge and accept that the information provided is correct.",
                          fr: "Je reconnais et accepte par la présente que les informations fournies sont correctes.",
                          kiny: "Ndemera ko amakuru yatanzwe ari ukuri.",
                        },
                        this.props.language
                      )}
                    </div>
                  </div>
                </div>
                {this.state.error?.target === "main" && (
                  <div className="col-span-12">
                    <Alert
                      alertType={AlertType.DANGER}
                      title={this.state.error.msg}
                      close={() => this.setState({ error: null, success: "" })}
                    />
                  </div>
                )}
                {this.state.success !== "" && (
                  <div className="col-span-12">
                    <Alert
                      alertType={AlertType.SUCCESS}
                      title={this.state.success}
                      close={() => this.setState({ error: null, success: "" })}
                    />
                  </div>
                )}
                <div className="col-span-12 flex flex-row items-center justify-end gap-2">
                  <div
                    onClick={this.props.onGoBack}
                    className="bg-white hover:bg-red-600 hover:text-white px-4 py-2 font-bold w-max rounded-md cursor-pointer"
                  >
                    {GenerateLangText(
                      {
                        eng: "Cancel",
                        fr: "Annuler",
                        kiny: "Guhagarika",
                      },
                      this.props.language
                    )}
                  </div>
                  <div
                    onClick={() => this.SubmitServiceComplaint()}
                    className="bg-green-600 text-white hover:bg-green-700 px-4 py-2 font-bold w-max rounded-md cursor-pointer flex items-center justify-center gap-2"
                  >
                    {this.state.loading === true && (
                      <div>
                        <AiOutlineLoading3Quarters className="animate-spin text-2xl text-yellow-300" />
                      </div>
                    )}
                    {this.state.loading === false ? (
                      <span>
                        {GenerateLangText(
                          {
                            eng: "Save & Continue",
                            fr: "Enregistrer & Continuer",
                            kiny: "Ohereza Amakuru & Ukomeze",
                          },
                          this.props.language
                        )}
                      </span>
                    ) : (
                      <span className="animate__animated animate__fadeIn animate__infinite">
                        {GenerateLangText(
                          {
                            eng: "Saving Changes...",
                            fr: "Enregistrer les modifications...",
                            kiny: "Kohereza amakuru...",
                          },
                          this.props.language
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  system,
  services,
}: StoreState): { system: System; services: ServicesStore } => {
  return { system, services };
};

export const ProductComplaintForm = connect(mapStateToProps, {
  FC_SubmitProductComplaint,
  FC_GetServiceComplaintTypes,
  FC_AddSocketComplaint,
})(_ProductComplaintForm);
