import React from "react";
import { RiWifiOffLine } from "react-icons/ri";
import { LanguageEnum } from "../SelectService/SelectService";
import { GenerateLangText } from "../../utils/functions";

const NetworkError = (props: { lang: LanguageEnum }) => {
  return (
    <div className="bg-yellow-100 rounded-md fixed bottom-1 right-1 cursor-pointer p-3 animate__animated animate__fadeIn z-50">
      <div className="w-full flex flex-row items-center gap-2">
        <div>
          <div className=" h-14 w-14 bg-yellow-200 rounded-full flex items-center justify-center">
            <RiWifiOffLine className="text-4xl text-yellow-600" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-3 justify-between -mt-2">
            <div className="text-xl font-extrabold text-yellow-800 mt-2">
              {GenerateLangText(
                {
                  eng: "Network disconnected",
                  fr: "Réseau déconnecté",
                  kiny: "Internet yavuyeho",
                },
                props.lang
              )}
            </div>
            <div>
              <a
                href="/"
                className="truncate bg-white rounded px-2 py-1 mb-1 text-sm font-bold w-max"
              >
                {GenerateLangText(
                  {
                    eng: "Reload",
                    fr: "Recharger",
                    kiny: "Kanda hano",
                  },
                  props.lang
                )}
              </a>
            </div>
          </div>
          <span className="text-base font-light text-yellow-800">
            {GenerateLangText(
              {
                eng: "You are offline now, Please check your network adapter and try again!",
                fr: "Vous êtes maintenant hors ligne, veuillez vérifier votre adaptateur réseau et réessayer !",
                kiny: "Nta network ufite, reba niba uri ku muyoboro wa interineti neza",
              },
              props.lang
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
