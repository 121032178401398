import { Action, ActionTypes, System } from "../actions";
import { LanguageEnum } from "../components/SelectService/SelectService";
// default state
const defaultState: System = {
  side_nav: false,
  basic_info: null,
  access_details: null,
  error: "",
  success: "",
  language: LanguageEnum.ENG,
  FAQs: null,
  communication_channels: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const systemReducer = (state: System = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.GET_SYSTEM_BASIC_INFO:
      return {
        ...state,
      };
    case ActionTypes.SET_SYSTEM_ERROR_MESSAGE:
      return {
        ...state,
        error: action.payload,
        success: "",
      };
    case ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.payload,
        error: "",
      };
    case ActionTypes.GET_ALL_ACCESS_DETAILS:
      return {
        ...state,
        access_details: action.payload,
      };
    case ActionTypes.CHANGE_SYSTEM_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case ActionTypes.GET_FAQ_ACTION:
      return {
        ...state,
        FAQs: action.payload,
      };
    case ActionTypes.GET_COMMUNICATION_CHANNELS:
      return {
        ...state,
        communication_channels: action.payload,
      };
    default:
      return state;
  }
};
