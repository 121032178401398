import React, { Component } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { CopyTextClipBoard, GenerateLangText } from "../../utils/functions";
import { LanguageEnum } from "../../components/SelectService/SelectService";

interface ComplaintReferenceCodeProps {
  submissionData: {
    customer_id: string;
    message: string;
    service_complaint_id: string;
    complaint_tracking_code: string;
  };
  language: LanguageEnum;
}
interface ComplaintReferenceCodeState {}

export class ComplaintReferenceCode extends Component<
  ComplaintReferenceCodeProps,
  ComplaintReferenceCodeState
> {
  constructor(props: ComplaintReferenceCodeProps) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="p-8 animate__animated animate__zoomIn animate__faster">
        <div className="flex flex-col items-center justify-center text-center">
          <div>
            <BsCheckCircleFill className="text-8xl text-green-600 animate__animated animate__bounceIn animate__infinite animate__slower" />
          </div>
          <div className="text-2xl text-green-600 font-bold mt-4 mb-1">
            {GenerateLangText(
              {
                eng: "Thank you! Your Query has been submitted successfully",
                fr: "Merci! Votre requête a été soumise avec succès",
                kiny: "Murakoze! Ikibazo/Icyifuzo cyawe cyatanzwe neza",
              },
              this.props.language
            )}
          </div>
          <div className="mb-4">
            {GenerateLangText(
              {
                eng: `Feedback will be sent to your email or phone number so that you can track your Query response through our platform using the following reference number`,
                fr: `Les commentaires seront envoyés à votre adresse e-mail ou à votre numéro de téléphone afin que vous puissiez suivre votre réponse à la requête via notre plate-forme en utilisant le numéro de référence suivant`,
                kiny: `Ibisubizo bizoherezwa kuri imeri yawe cyangwa nimero ya terefone kugirango ubashe gukurikirana igisubizo cyIkibazo/Icyifuzo cyawe ukoresheje urubuga rwacu ukoresheje nimero ikurikira.`,
              },
              this.props.language
            )}
          </div>
          <div className="mt-2 w-full md:w-96 animate__animated animate__fadeIn animate__slower">
            <div className="flex flex-row items-center justify-between rounded-xl border-4 border-primary-700 w-full overflow-hidden p-1">
              <input
                type="text"
                value={this.props.submissionData.complaint_tracking_code}
                disabled={true}
                className="h-11 w-full text-center font-extrabold text-yellow-600 text-2xl flex items-center justify-center"
              />
              <div
                onClick={() =>
                  CopyTextClipBoard(
                    this.props.submissionData.complaint_tracking_code
                  )
                }
                className="w-max h-11 text-primary-800 font-bold border border-primary-300 bg-primary-100 flex items-center justify-center rounded-lg px-4 cursor-pointer"
              >
                Copy
              </div>
            </div>
          </div>
          <div className="pt-6">
            <Link
              to="/"
              className="bg-gray-100 rounded-lg font-bold items-center justify-center cursor-pointer hover:bg-primary-100 px-5 py-2 hover:text-primary-800 w-max"
            >
              Continue to Homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
