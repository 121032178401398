import React, { ReactNode } from "react";

const Container = (props: { className?: string; children: ReactNode }) => {
  return (
    <div className={`container mx-auto pt-16 lg:px-10 ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Container;
